import React from 'react';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Typography from '@mui/material/Typography';


function ListLojas2(props) {
  return (
    <div className='sh2' style={{
      display: 'flex', flexDirection: 'row', flex: 1, width: 250, backgroundColor: '#fff', borderRadius: 10, marginRight: 10, paddingLeft: 2, paddingRight: 5, shadowColor: '#adadad',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 }, marginTop: 10, marginLeft: 2, marginBottom: 10
    }} onClick={props.onPress}>

      <div style={{ opacity: props.isAberto === 'Aberto' ? 1 : 0.5, height: 95, marginTop: 13 }}>
        <img
          src={`${props.Link}`}
          srcSet={props.Link}
          alt="Logo"
          loading="lazy"
          style={{
            marginTop: 0,
            width: 65,
            height: 65,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 50,
            marginLeft: 5
          }} />
      </div>
      <div opacity={props.isAberto === 'Aberto' ? 1 : 0.5} style={{ marginLeft: 10, width: 150, }}>
        <div style={{ height: 35, marginTop: 13 }}>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: 12, width: '100%', }} numberOfLines={1}>
            {props.Nome}
          </Typography>
        </div>
        <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, }} numberOfLines={2}>
            <TwoWheelerOutlinedIcon style={{ fontSize: 10, marginRight: 5 }} />
            {mascaraValor(eval(parseFloat(props.Distancia)).toFixed(2))} Km
          </Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, color: props.isAberto === 'Aberto' ? 'green' : 'red' }} numberOfLines={2}>
            <MeetingRoomIcon style={{ fontSize: 10, color: props.isAberto === 'Aberto' ? 'green' : 'red' }} />
            <Typography variant="subtitle1" style={{ marginLeft: 15, fontFamily: 'Montserrat', fontSize: 10, marginTop: -18 }}> {props.isAberto}</Typography >
          </Typography>
        </div>
      </div>
    </div>
  )
}

function mascaraValor(valor) {
  valor = valor.toString().replace(/\D/g, "");
  valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
  return valor
}

export default ListLojas2;


export function mascaraValor(valor) {
    valor = valor.toString().replace(/\D/g, "");
    valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
    return valor
}

export function mascaraTelefone(valor) {
    var Str = ""
    console.log("Aqui ", valor.substring(0, 1))
    if (valor.substring(0, 1) === "0") {
        Str = valor.substring(1, valor.length)
    } else {
        Str = valor
    }

    if (Str.length === 11) {
        // 99 9 9999-9999
        Str = Str.replace(/\D/g, '').replace(/(\d{2})(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4')
    } else if(Str.length === 10) {
        // 99 9999-9999
        Str = Str.replace(/\D/g, '').replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
    } else {
        Str = Str;
    }

    return Str
}

export function mascaraCartao(Numero) {
    return `**** **** **** ${Numero.substr(-4)}`
}

export function bandeira(band){
    if(band === 'Visa'){
        return 'cc-visa'
    } else if (band === 'Master') {
        return 'cc-mastercard'
    } else if (band === 'Diners') {
        return 'cc-diners-club'
    } else if (band === 'Amex') {
        return 'cc-amex'
    } else if (band === 'Discover') {
        return 'cc-discover'
    } else if (band === 'Hipercard') {
        return 'credit-card-alt'
    } else if (band === 'Elo') {
        return 'credit-card-alt'
    } else if (band === 'JCB') {
        return 'cc-jcb'
    } else {
        return 'cc-jcb'
    }
}
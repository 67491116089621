import React from 'react';
import { mascaraValor } from './../app/utilites/utilites'
import Typography from '@mui/material/Typography';

export default function ProdLoja(props) {
  return (
    <div style={{
      flexDirection: 'row', display: 'flex',
      marginBottom: 15, width: '95%', height: 80
    }} onClick={props.onPress}>
      <div>
        <img
          src={props.Link}
          style={{
            marginTop: 0,
            width: 70,
            height: 70,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 15
          }}/>
      </div>
      <div style={{
        paddingLeft: 10, paddingTop: 0, width: '80%', height: 80,
        borderBottomColor: '#838383', borderBottomWidth: 0,
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1"  style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fd5e37', fontWeight: 800 }}>{props.nome}</Typography>
          {props.isPromo === 1 ? <div style={{ width: 30, height: 15, backgroundColor: '#7DC8C3', alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 9, width: '100%', textAlign: 'center', color: 'white', fontWeight: 800 }} numberOfLines={2}>
              - {props.vlDesc}%
            </Typography>
          </div> : null}
        </div>
        <Typography numberOfLines={2} style={{
          fontFamily: 'Montserrat',
          fontSize: 12,
          marginTop: -4,
          color: '#6e6e6e',
          height: 30,
        }}>{props.obs.length > 0 ? props.obs.substring(0, 60) : '(Produto sem descrição)'}</Typography>
        {props.preco.toFixed(2) === "0.00" ?
          <Typography numberOfLines={1} style={{
            fontFamily: 'Montserrat',
            fontSize: 12,
            color: '#fd5e37',
            marginTop: 5, fontWeight: 600
          }}>Customizável</Typography>
          :
          <Typography numberOfLines={1} style={{
            fontFamily: 'Montserrat',
            fontSize: 14,
            color: '#fd5e37',
            marginTop: 5, fontWeight: 800
          }}>R$ {
              mascaraValor(props.preco.toFixed(2))
            }</Typography>
        }
      </div>
    </div>
  )
}

export function ProdLoja2(props) {
  return (
    <div style={{ display: 'flex',
      flexDirection: 'row', display: 'flex',
      marginBottom: 15, width: '95%', paddingBottom: 20
    }} >
      <div>
        <img
          src={props.Link}          
          style={{
            marginTop: 0,
            width: 90,
            height: 90,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 15
          }}/>
      </div>
      <div style={{
        paddingLeft: 10, paddingTop: 1, width: '80%', height: 80,
        borderBottomColor: '#838383', borderBottomWidth: 0,
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: 14, color: '#fd5e37' }}>{props.nome}</Typography>
          {props.isPromo === 1 ? <div style={{ width: 30, height: 15, backgroundColor: '#7DC8C3', alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
          <Typography  style={{ fontFamily: 'Montserrat', fontSize: 9, width: '100%', textAlign: 'center', color: 'white', fontWeight: 800 }} numberOfLines={2}>
              - {props.vlDesc}%
            </Typography>
          </div> : null}
        </div>
        <Typography  numberOfLines={7} style={{
          fontFamily: 'Montserrat',
          fontSize: 12,
          color: '#6e6e6e',
        }}>{props.obs.length > 0 ? props.obs : '(Produto sem descrição)'}</Typography>
        <Typography  numberOfLines={1} style={{
          fontFamily: 'Montserrat',
          textAlign: 'justify',
          fontSize: 14,
          color: '#fd5e37',
          fontWeight: 800,
          marginTop: 0
        }}>R$ {mascaraValor(props.preco.toFixed(2))}</Typography>
      </div>
    </div>
  )
}
import { CarrinhoReducer }           from './stateCarrinho';
import { CurrentTabReducer }         from './stateTabs';
import { CurrentUserReducer }        from './stateUsuario';
import { ProdSelcReducer }           from './stateProdSelc';
import { CategoriasReducer }         from './stateCategorias';
import { filtroReducer }             from './stateFiltro';
import { CurrentCartoesReducer }     from './stateCartoes';
import { combineReducers }           from 'redux';







export const Reducers = combineReducers({
  CarrinhoReducer: CarrinhoReducer,
  CurrentTabReducer: CurrentTabReducer,
  CurrentUserReducer: CurrentUserReducer,
  ProdSelcReducer: ProdSelcReducer,
  CategoriasReducer: CategoriasReducer,
  filtroReducer: filtroReducer,
  CurrentCartoesReducer: CurrentCartoesReducer
});
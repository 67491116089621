import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';

export default function ListEnderecos(props) {
  return (
    <div style={{
      width: "100%", height: 80, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: 'white', marginTop: 10, display: 'flex',
      borderRadius: 10, borderWidth: 0.5, borderColor: props.padrao ? '#fd5e37' : '#fff', shadowColor: '#adadad',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 },
    }}
      onClick={props.onPress}
    >
      <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
        <div style={{ width: 50, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <LocationOnIcon style={{ fontSize: 25, color: '#777777' }} />
        </div>
        <div style={{ paddingTop: 10, paddingLeft: 10 }}>
          <Typography variant="subtitle1" style={{ color: '#fd5e37', fontFamily: 'Montserrat', fontSize: 14, width: '100%', fontWeight: 'bold' }} numberOfLines={2}>
            {props.titulo}
          </Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, width: '100%', }} numberOfLines={2}>
            {props.endereco}
          </Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, width: '100%',  }} numberOfLines={2}>
            {props.obs}
          </Typography>
        </div>
      </div>
      <div>
        <div style={{ paddingRight: 10, paddingTop: 10 }}>
          {props.padrao ? <CheckCircleIcon style={{ fontSize: 15, color: '#fd5e37' }} />
            :
            null}
        </div>
      </div>
    </div>
  )
}

export function ListEnderecos2(props) {
  return (
    <div style={{
      width: 160, maxHeight: 50, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: props.padrao ? '#fd5e37' : '#fff', marginTop: 0, marginRight: 10,
      borderRadius: 10, shadowColor: '#adadad', display: 'flex',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 }, marginTop: 2
    }}
      onClick={props.onPress}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{display: 'flex', width: 40, alignItems: 'center', justifyContent: 'center' }}>
          <LocationOnIcon style={{ fontSize: 20, color: props.padrao ? '#fff' : '#777777' }} />
        </div>
        <div style={{ paddingTop: 7, paddingLeft: 0 }}>
          <Typography variant="subtitle1" style={{ color: props.padrao ? '#fff' : '#fd5e37', fontSize: 14, fontFamily: 'Montserrat', fontWeight: 'bold' }} numberOfLines={1}>
            {props.titulo}
          </Typography>

          <Typography variant="subtitle1" style={{ color: props.padrao ? '#fff' : '#000', fontFamily: 'Montserrat', fontSize: 10, width: 110, fontWeight: 'bold', maxHeight: 50 }} numberOfLines={1}>
            {props.endereco.length > 10 ? props.endereco.substring(0,16) : props.endereco}
          </Typography>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}

export function ListEnderecos4(props) {
  return (
    <div style={{
      display: 'flex',
      width: 110, height: 50, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: props.padrao ? '#fd5e37' : '#fff', marginTop: 0, marginRight: 10,
      borderRadius: 10, borderWidth: 0.5, borderColor: '#fff', shadowColor: '#adadad',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 }, marginTop: 2, marginLeft: 3
    }}
      onClick={props.onPress}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{display: 'flex', width: 40, alignItems: 'center', justifyContent: 'center' }}>
          <LocationOnIcon style={{ fontSize: 20, color: props.padrao ? '#fff' : '#777777' }} />
        </div>
        <div style={{ paddingTop: 7, paddingLeft: 4, width: 65 }}>
          <Typography variant="subtitle1" style={{ color: props.padrao ? '#fff' : '#fd5e37', fontSize: 14, fontFamily: 'Montserrat', fontWeight: 'bold' }} numberOfLines={1}>
            {props.titulo}
          </Typography>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}

export function ListEnderecos3(props) {
  return (
    <div style={{
      display: 'flex',
      width: "100%", height: 50, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: props.padrao ? '#fd5e37' : '#fff', marginTop: 0, marginRight: 10,
      borderRadius: 10, borderWidth: 0.5, borderColor: '#fff', shadowColor: '#adadad',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 }, marginTop: 2
    }}
      onPress={props.onPress}
    >
      <div style={{ flex: 1, flexDirection: 'row' }}>
        <div style={{ width: 40, alignItems: 'center', justifyContent: 'center' }}>
          <LocationOnIcon style={{ fontSize: 20, color: props.padrao ? '#fff' : '#777777' }} />
        </div>
        <div style={{ paddingTop: 7, paddingLeft: 0 }}>
          <Typography variant="subtitle1" style={{ color: props.padrao ? '#fff' : '#fd5e37', fontSize: 14, fontFamily: 'Montserrat', fontWeight: 'bold' }} numberOfLines={1}>
            Sem endereços cadastrados
          </Typography>

          <Typography variant="subtitle1" style={{ color: props.padrao ? '#fff' : '#000', fontFamily: 'Montserrat', fontSize: 10, width: 110, fontWeight: 'bold' }} numberOfLines={1}>
            Clique para cadastrar um endereço de entrega
          </Typography>

        </div>
      </div>
      <div>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react';
import Splash from './app/screens/splash/splash'


import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

export default function Rotas() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/app" component={Splash} />
        <Redirect from="*" to="/app" />
      </Switch>
    </BrowserRouter>
  );
}

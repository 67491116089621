import React from 'react';
import Typography from '@mui/material/Typography';

export default function GrupLoja(props) {
  return (
    <div style={{}} onClick={props.onPress}>
      <Typography variant="subtitle1" style={{color: props.ativo ? '#353535' : '#a3a3a3', 
      margin: 15, fontFamily: 'Montserrat',
      textAlign: 'left', fontSize: 17}}>{props.nome}</Typography>
    </div>
  );
}

export const UPDATE_CAR = value => ({
  type: 'UPDATE_CAR',
  produto: value
});

export const SET_CAR_EMP = value => ({
  type: 'SET_CAR_EMP',
  empresa: value
});

export const DEL_ITEM_CAR = value => ({
  type: 'DEL_ITEM_CAR',
  x: value
});

export const SET_PROD_SELEC = value => ({
  type: 'SET_PROD_SELEC',
  produto: value
});

export const SET_CURRENT_TAB = value => ({
  type: 'SET_CURRENT_TAB',
  tab: value
});

export const SET_USUARIO_LOG = value => ({
  type: 'SET_USUARIO_LOG',
  id: value
});

export const SET_INF_USUARIO_LOG = value => ({
  type: 'SET_INF_USUARIO_LOG',
  obj: value
});

export const ALTERAR_PAGAMENTO = value => ({
  type: 'ALTERAR_PAGAMENTO',
  pagamento: value
});

export const SET_VL_TROCO = value => ({
  type: 'SET_VL_TROCO',
  troco: value
});

export const SET_CATEGORIAS = value => ({
  type: 'SET_CATEGORIAS',
  categorias: value
});

export const SET_FILTRO_RED = value => ({
  type: 'SET_FILTRO_RED',
  categoria: value
});

export const LIMPAR_CAR = value => ({
  type: 'LIMPAR_CAR',
  limpar: value
});

export const SET_ADC_SELEC = value => ({
  type: 'SET_ADC_SELEC',
  adc: value
});

export const UP_ADC_SELEC = value => ({
  type: 'UP_ADC_SELEC',
  adc: value
});

export const EMPTY_ADC_SELEC = value => ({
  type: 'EMPTY_ADC_SELEC',
  adc: value
});

export const SET_ADC_PROD = value => ({
  type: 'SET_ADC_PROD',
  obj: value
});

export const DEL_ADC_PROD = value => ({
  type: 'DEL_ADC_PROD',
  obj: value
});

export const EMPTY_ADC_PROD = value => ({
  type: 'EMPTY_ADC_PROD',
  obj: value
});

export const SET_PROD_ADC = value => ({
  type: 'SET_PROD_ADC',
  adc: value
});

export const SET_CART_DEFAULT = value => ({
  type: 'SET_CART_DEFAULT',
  obj: value
});

export const SET_MY_CARTOES = value => ({
  type: 'SET_MY_CARTOES',
  obj: value
});

export const SET_PAG_ONLINE = value => ({
  type: 'SET_PAG_ONLINE',
  obj: value
});

export const SET_ANUNCIO_LOG = value => ({
  type: 'SET_ANUNCIO_LOG',
  isAnuncio: value
});
import React from 'react';
import api from './../../../utilites/api';



export default async function enviarPedido(
  state,
  vl_pedido,
  vl_produtos,
  valor_entrega,
  valor_desc, produtos, pagamento, itens, cartao, cupom, pagCart, desconto_loja, props, valor_entrega_f) {
  var Exit = false;
  try {

    var post = {
      id_usuario: state.id_user,
      id_endereco: state.id_end_user,
      pagamento: pagamento,
      valor_pedido: vl_pedido,
      valor_produtos: vl_produtos,
      valor_entrega: valor_entrega,
      valor_desc: valor_desc,
      id_loja: state.loja.id,
      comissao: state.loja.comissao,
      observacao: "",
      teste: vl_produtos,
      produtos: await getItens(itens),
      cartao: cartao,
      cupom: cupom,
      pagCart: pagCart,
      desconto_loja: desconto_loja,
      valor_entrega_f: valor_entrega_f
    }
    console.log("Ok:..", post)
    const pedido = await api.post(`setPedido`, post);

    console.log('aqui: ', pedido.data)

    if (pedido.status === 200) {

      if (!pedido.data.error) {
        if (pedido.data.pedido.pagamento === 'Pix') {
          alert("Falta pouco para finalizar seu pedido, Utilize seu aplicativo bancário para escanear ou copiar o QR code abaixo e finalizar o pagamento com mais rapidez, via PIX!")
          props.history.push({
            pathname: `/app/pedidoPix`,
            state: {
              Pedido: pedido.data.pedido
            }
          });
          Exit = true
        } else {
          alert("Seu pedido foi realizado com sucesso!")
          Exit = true
        }
      } else {
        alert(pedido.data.message)
        Exit = false
      }


    } else {
      alert("Erro ao enviar pedido")

      Exit = false
    }

  } catch (error) {
    console.log('Erro: ', error)
    alert("Erro ao enviar pedido")

    Exit = false
  }

  return Exit;
}


async function getItens(itens) {

  var Exit = [];

  for (var i = 0; i < itens.length; i++) {
    var temp = {
      id_produto: itens[i].id,
      quantidade: itens[i].quantidade,
      valor: itens[i].total,
      adicional: itens[i].adc,
      observacao: itens[i].observacao
    }
    console.log("teste:", temp)
    Exit.push(temp);
  }

  return Exit;

}
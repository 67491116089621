import React, { Component } from 'react';
import Typography from '@mui/material/Typography';

export default class ListCategorias extends Component {

  render() {
    return (
      <div style={{ display: 'flex', width: 65, height: 80, alignItems: 'center', marginRight: 10 }}
        onClick={this.props.onPress}>
        <img
          src={this.props.Link}
          style={{
            marginTop: 0,
            width: 55,
            height: 55,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 15
          }} />

        {/* <Text style={{ fontFamily: 'Montserrat-SemiBold', color: '#fd5e37', marginTop: 5, fontSize: 11 }}>
          {this.props.Nome}
        </Text> */}
      </div>
    )
  }

}

export function ListCategorias2(props) {
  return (
    <div style={{ width: 78, height: 110, alignItems: 'center', marginRight: 0 }} onClick={props.onPress}>
      <img
        src={props.Link}
        style={{
          marginTop: 0,
          width: 70,
          height: 70,
          backgroundColor: '#d8dadc',
          position: 'relative',
          borderRadius: 15
        }} />
      <Typography variant="subtitle1" style={{ width: '100%', marginTop: 5, color: '#fd5e37', fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600 }}>
        {props.Nome}
      </Typography>
    </div>
  )
}
const state = {
  idUsuario: 0,
  nomeUsuario: '',
  telefoneUsuario: '',
  tokenUsuario: '',
  isAnuncio: false
};

export const CurrentUserReducer = (stateAt = state, action) => {
  switch (action.type) {
    case 'SET_USUARIO_LOG':
      return {
        ...stateAt,
        idUsuario: action.id
      };
    case 'SET_INF_USUARIO_LOG':
      return {
        ...stateAt,
        nomeUsuario: action.obj.nome,
        telefoneUsuario: action.obj.telefone,
        tokenUsuario: action.obj.token_app,
      };
    case 'SET_ANUNCIO_LOG':
      return {
        ...stateAt,
        isAnuncio: action.isAnuncio
      };
    default:
      return stateAt;
  }
};
import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  SET_CATEGORIAS,
  SET_FILTRO_RED,
  SET_CURRENT_TAB,
  SET_ANUNCIO_LOG,
} from "./../../../redux/actions/index";
import ListLojas from "./../../../../components/listLojas";
import ListCategorias from "./../../../../components/listCategoria";
import ListLojas2 from "./../../../../components/listLojas2";
//import NoLojas from './../../../components/NoLojas'
import api from "./../../../utilites/api";
import VariaveisGlobais from "./../../../utilites/VariaveisGlobais";
import Skeleton from "@mui/material/Skeleton";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Typography from "@mui/material/Typography";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import { ScrollView, RefreshControl } from "@cantonjs/react-scroll-view";
// import { Container } from './styles';

class Home extends Component {
  state = {
    nomeEnd: "",
    categorias: [],
    lat: 0,
    lng: 0,
    lojas: [],
    lojasAll: [],
    isLoading: false,
    isErro: false,
    isAnuncio: false,
    id_user: 0,
    lojasPromo: [],
    banner: [],
    anuncio: [],
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    if (
      localStorage.getItem("@foodja_app/endereco/id") !== undefined ||
      localStorage.getItem("@foodja_app/endereco/id") !== null
    ) {
      await this.setState({
        nomeEnd: localStorage.getItem("@foodja_app/endereco/nome"),
        lat: localStorage.getItem("@foodja_app/endereco/lat"),
        lng: localStorage.getItem("@foodja_app/endereco/long"),
        id_user: localStorage.getItem("@foodja_app/usuario_id"),
      });
      this.getServidor();
    } else {
      this.props.history.push("/");
    }
  }

  async getServidor() {
    this.setState({ isLoading: true, isErro: false });
    try {
      const data = new FormData();
      data.append("lat", this.state.lat);
      data.append("lng", this.state.lng);
      data.append("user", this.state.id_user);

      const categoria = await api.get("getCategoriasApp");
      const lojas = await api.post("getLojas", data);
      const lojasAll = await api.post("getLojasAll", data);
      const banner = await api.post("getBanners", data);
      const anuncio = await api.post("getAnuncio", data);

      console.log("aqui: ", lojas.data);
      if (lojasAll.status === 200) {
        await this.setState({
          categorias: categoria.data.data,
          lojas: lojas.data.data.filter((i) => i.em_promo !== 1),
          lojasAll: lojasAll.data.data,
          lojasPromo: lojas.data.data.filter((i) => i.em_promo === 1),
          banner: banner.data.data,
          anuncio: anuncio.data.data,
        });

        const { SET_CATEGORIAS } = this.props;

        if (anuncio.data.data.length > 0) {
          if (this.props.idUsuario.isAnuncio) {
            this.setState({ isAnuncio: false });
          } else {
            this.setState({ isAnuncio: true });
          }
        } else {
          this.setState({ isAnuncio: false });
        }

        console.log("Lojas promo", this.state.lojasPromo);
        SET_CATEGORIAS(categoria.data.data);
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false, isErro: true });
        console.log("Erro", "zxczx");
      }
    } catch (error) {
      this.setState({ isLoading: false, isErro: true });
      console.log("Erro", error);
    }
  }

  render() {
    //return this.noCobertura()
    if (this.state.isLoading) {
      return this.skeleton();
    } else {
      if (this.state.isErro) {
        return this.noConection();
      } else {
        if (this.state.lojasAll.length === 0) {
          return this.noCobertura();
        } else {
          return this.layout();
        }
      }
    }
  }

  layout() {
    return (
      <div style={{ width: "96%" }}>
        {this.header()}
        <div style={{ paddingLeft: 18, marginTop: 20 }}>
          {this.categorias()}
        </div>
        {/* {this.state.anuncio.length > 0 ? this.mdAnuncio() : null} */}
        {this.state.banner.length > 0 ? (
          <div
            style={{
              paddingLeft: 14,
              marginTop: 0,
              paddingRight: 13,
              marginBottom: 13,
            }}
            onClick={async () => {
              this.setState({ isLoading: true });
              if (this.state.banner[0].id_loja !== "0") {
                try {
                  const data = new FormData();
                  data.append("lat", this.state.lat);
                  data.append("lng", this.state.lng);
                  data.append("user", this.state.id_user);
                  data.append("id_Emp", this.state.banner[0].id_loja);

                  const infLoja = await api.post(`getLoja`, data);

                  if (infLoja.status === 200) {
                    this.props.history.push({
                      pathname: `/app/loja/${infLoja.data.data[0].id}`,
                    });
                    this.setState({ isLoading: false });
                  } else {
                    this.setState({ isLoading: false, isErro: true });
                  }
                } catch (error) {
                  this.setState({ isLoading: false, isErro: true });
                  console.log("Erro", error);
                }
              }
            }}
          >
            <div
              style={{
                width: "100%",
                height: 80,
                backgroundColor: "#d8dadc",
                borderRadius: 10,
              }}
            >
              <img
                src={
                  this.state.banner[0].sever_img + this.state.banner[0].nome_img
                }
                style={{
                  width: "100%",
                  height: 80,
                  borderRadius: 10,
                }}
              />
            </div>
          </div>
        ) : null}

        <div style={{ paddingLeft: 18, marginTop: 0 }}>
          {/* {this.state.lojasPromo.length > 0 ? this.lojasProm() : null} */}
        </div>
        <div
          style={{
            paddingLeft: 18,
            marginTop: this.state.lojasPromo.length > 0 ? 25 : 0,
          }}
        >
          {this.lojas()}
        </div>
        <div style={{ paddingLeft: 18, marginTop: 25 }}>
          {this.outrasLojas()}
        </div>
        <div style={{ width: 50, height: 120 }} />
      </div>
    );
  }

  lojas() {
    const vglobais = new VariaveisGlobais();
    return (
      <div>
        <Typography
          variant="subtitle1"
          style={{
            color: "#fd5e37",
            fontSize: 15,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Estabelecimentos próximos
        </Typography>
        <div
          className="scow"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 6,
            marginLeft: 0,
            height: 200,
            paddingBottom: 5,
            paddingLeft: 0,
            overflowY: "scroll",
          }}
        >
          {this.state.lojas.map((i, key) => (
            <ListLojas
              Link={`${vglobais.getHostImg}/${i.name_img}`}
              onPress={async () => {
                this.props.history.push({
                  pathname: `/app/loja/${i.id}`,
                });
                // this.props.navigation.navigate('InfLojas', {
                //   loja: i,
                //   user: this.state.id_user
                // });
              }}
              Nome={i.nome}
              Distancia={i.Distancia}
              isAberto={i.is_aberto}
              key={key}
              valor_desc={i.valor_desc}
              em_promo={i.em_promo}
              vl_Entrega={i.vl_Entrega}
              entregaFree={i.entrega_free}
            />
          ))}
          {/* {this.state.lojas.length === 0 ? <NoLojas /> : null} */}
          <div style={{ width: 180, height: 20 }} />
        </div>
      </div>
    );
  }

  outrasLojas() {
    const vglobais = new VariaveisGlobais();
    return (
      <div>
        <Typography
          variant="subtitle1"
          style={{
            color: "#fd5e37",
            fontSize: 15,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Mais estabelecimentos
        </Typography>
        <div
          className="scow"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 6,
            marginLeft: 0,
            height: 120,
            paddingBottom: 5,
            paddingLeft: 0,
            overflowY: "scroll",
          }}
        >
          {this.state.lojasAll.map((i, key) => (
            <ListLojas2
              Link={`${vglobais.getHostImg}/${i.name_img}`}
              Nome={i.nome}
              Distancia={i.Distancia}
              isAberto={i.is_aberto}
              key={key}
              onPress={async () => {
                this.props.history.push({
                  pathname: `/app/loja/${i.id}`,
                });
              }}
              vl_Entrega={i.vl_Entrega}
              entregaFree={i.entrega_free}
            />
          ))}
          <div style={{ width: 180, height: 20 }} />
        </div>
      </div>
    );
  }

  categorias() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{}}>
        <Typography
          variant="subtitle1"
          style={{
            color: "#fd5e37",
            fontSize: 15,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Categorias
        </Typography>
        <div
          className="scow"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 6,
            marginLeft: 0,
            height: 100,
            paddingBottom: 5,
            paddingLeft: 0,
            overflowY: "auto",
          }}
        >
          {this.state.categorias.map((i, key) => (
            <ListCategorias
              Link={`${vglobais.getHostImg}/${i.nome_img}`}
              Nome={i.nome}
              key={key}
              onPress={() => {
                const { SET_FILTRO_RED, SET_CURRENT_TAB } = this.props;
                SET_FILTRO_RED(i.nome);
                SET_CURRENT_TAB(1);
              }}
            />
          ))}
          <div style={{ width: 100, height: 20 }} />
        </div>
      </div>
    );
  }

  noCobertura() {
    return (
      <div style={{ width: "96%" }}>
        {this.header()}
        <div
          style={{
            marginTop: "35%",
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SearchOffOutlinedIcon style={{ fontSize: 120, color: "#8A8A8A" }} />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 10,
            width: "90%",
            alignItems: "center",
            flexDirection: "column",
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontSize: 15, fontFamily: "Montserrat" }}
          >
            WHOOPS!!!
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 10, fontSize: 12, fontFamily: "Montserrat" }}
          >
            Lamento, mas não temos estabelecimentos parceiros na sua área!!
          </Typography>
          <div
            style={{
              marginTop: 10,
              height: 40,
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              console.log("Apertei");
              this.getServidor();
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: 12,
                fontFamily: "Montserrat",
                color: "#fd5e37",
                fontWeight: "600",
              }}
            >
              Tente novamente
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  noConection() {
    return (
      <div style={{ width: "96%" }}>
        {this.header()}
        <div
          style={{
            marginTop: "35%",
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: "#8A8A8A" }} />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 10,
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontSize: 15, fontFamily: "Montserrat" }}
          >
            WHOOPS!!!
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 10, fontSize: 12, fontFamily: "Montserrat" }}
          >
            Ocorreu um erro na cominicação com o servidor!!
          </Typography>
          <div
            style={{
              marginTop: 10,
              height: 40,
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              console.log("Apertei");
              this.getServidor();
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: 12,
                fontFamily: "Montserrat",
                color: "#fd5e37",
                fontWeight: "600",
              }}
            >
              Tente novamente
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  skeleton() {
    return (
      <div style={{ width: "96%" }}>
        {this.header()}
        <div
          style={{
            paddingLeft: 18,
            marginTop: 20,
            display: "flex",
            backgroundColor: "#fff",
            flex: 1,
          }}
        >
          <div>
            <div style={{}}>
              <Skeleton style={{ width: 120, height: 20, borderRadius: 4 }} />
              <div
                style={{ marginTop: 15, flexDirection: "row", display: "flex" }}
              >
                <Skeleton style={{ width: 80, height: 100, borderRadius: 4 }} />
                <Skeleton
                  style={{
                    width: 80,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
                <Skeleton
                  style={{
                    width: 80,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
                <Skeleton
                  style={{
                    width: 80,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Skeleton style={{ width: 240, height: 20, borderRadius: 4 }} />
              <div style={{ flexDirection: "row", display: "flex" }}>
                <Skeleton
                  style={{ width: 100, height: 180, borderRadius: 4 }}
                />
                <Skeleton
                  style={{
                    width: 100,
                    height: 180,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <Skeleton style={{ width: 250, height: 20, borderRadius: 4 }} />
              <div
                style={{ marginTop: 15, flexDirection: "row", display: "flex" }}
              >
                <Skeleton
                  style={{ width: 140, height: 100, borderRadius: 4 }}
                />
                <Skeleton
                  style={{
                    width: 140,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <Skeleton style={{ width: 250, height: 20, borderRadius: 4 }} />
              <div
                style={{ marginTop: 15, flexDirection: "row", display: "flex" }}
              >
                <Skeleton
                  style={{ width: 140, height: 100, borderRadius: 4 }}
                />
                <Skeleton
                  style={{
                    width: 140,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <Skeleton style={{ width: 250, height: 20, borderRadius: 4 }} />
              <div
                style={{ marginTop: 15, flexDirection: "row", display: "flex" }}
              >
                <Skeleton
                  style={{ width: 140, height: 100, borderRadius: 4 }}
                />
                <Skeleton
                  style={{
                    width: 140,
                    height: 100,
                    borderRadius: 4,
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  header() {
    return (
      <div style={styleHeader}>
        <div
          style={{
            height: 55,
            width: 170,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            if (localStorage.getItem("@foodja_app/usuario_id") > 0) {
              this.props.history.push({
                pathname: `/app/endereco`,
              });
            } else {
              if (
                window.confirm(
                  "Neste momento precisamos que você se identifique, faça seu login ou cadastro!"
                )
              ) {
                this.props.history.push("/app/login");
              }
            }
          }}
        >
          <LocationOnOutlinedIcon
            style={{ fontSize: 22, width: 25, color: "#fd5e37" }}
          />
          <Typography
            variant="subtitle1"
            style={{
              color: "#fd5e37",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "600",
              whiteSpace: "nowrap",
              maxWidth: "20%",
            }}
            numberOfLines={1}
          >
            {localStorage.getItem("@foodja_app/endereco/nome").length > 22
              ? localStorage
                  .getItem("@foodja_app/endereco/nome")
                  .substring(0, 22)
              : localStorage.getItem("@foodja_app/endereco/nome")}{" "}
            ...
          </Typography>
        </div>
        <div
          style={{
            height: 55,
            width: 50,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          onPress={() => {
            this.props.navigation.navigate("Favoritos", {
              servico: "",
            });
          }}
        >
          <FavoriteBorderOutlinedIcon
            style={{ fontSize: 20, width: 25, color: "#7DC8C3" }}
          />
        </div>
      </div>
    );
  }
}

const styleHeader = {
  width: "100%",
  display: "flex",
  height: 55,
  backgroundColor: "#ffffff",
  paddingLeft: 15,
  paddingTop: 20,
  paddingRight: 0,
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottomWidth: 0,
};

const mapStateToProps = (state) => ({
  idUsuario: state.CurrentUserReducer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { SET_CATEGORIAS, SET_FILTRO_RED, SET_CURRENT_TAB, SET_ANUNCIO_LOG },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);

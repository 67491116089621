import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import Saudacao from './../../../assets/saudacao.jpg'
import { Arredondado } from './../../../components/botoes'
import Typography from '@mui/material/Typography';
import { browserHistory } from 'react-router';
import Geocode from "react-geocode";
// import { Container } from './styles';

export default class BemVindo extends Component {

  state = {
    PermissaoLocation: false,
    isLoading: false,
    longitude: 0,
    latitude: 0,
  }

  async rotinaLocalizacao(localizar) {
    this.setState({ isLoading: true });

    if (navigator.geolocation) {
      //navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({ PermissaoLocation: true });
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    }, () => {
      this.setState({ PermissaoLocation: false });
      console.log('No location!!!');
    });

    if (localizar) {
      this.getCoordinates();
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  async getCoordinates() {
    console.log("ok")
    this.setState({ isLoading: true })

    Geocode.setApiKey("AIzaSyCFOW4NYmjJwSBbkkcbvB2tg4k0ZGkFTFk");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    Geocode.setLocationType("ROOFTOP");

    navigator.geolocation.getCurrentPosition((position) => {

      this.setState({
        latitude: position.coords.longitude,
        longitude: position.coords.latitude,
      });

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);

          localStorage.setItem('@foodja_app/endereco/id', '-1');
          localStorage.setItem('@foodja_app/endereco/nome', address);
          localStorage.setItem('@foodja_app/endereco/long', position.coords.longitude);
          localStorage.setItem('@foodja_app/endereco/lat', position.coords.latitude);
          var path = localStorage.getItem('@foodja_app/path_name').includes('/loja/') ? localStorage.getItem('@foodja_app/path_name') : '/';
          //alert(path);
          alert(localStorage.getItem('@foodja_app/path_name'));
          this.props.history.push(path);
        },
        (error) => {
          alert('Erro ao obter localização, tente novamente!');
          this.setState({ isLoading: false });
          console.log('Erro: ', error)
        }
      );

    }, () => {
      this.setState({ PermissaoLocation: false });
      alert('Erro ao obter localização, tente novamente!');
      console.log('No location!!!');
    });

  }

  render() {
    return (
      <div style={{
        width: '100%', height: '100vh',
        backgroundColor: '#fff', display: 'flex', alignItems: 'center',
        justifyContent: 'center', flexDirection: 'column'
      }}>
        <div style={{ justifyContent: 'left', flexDirection: 'row', display: 'flex', width: '95%', paddingTop: 15 }}>
          <Typography variant="h6" align='left' gutterBottom style={{ fontWeight: '800', color: '#fd5e37', marginLeft: 20, marginTop: 15, fontFamily: 'Montserrat', alignItems: 'left' }}>
            Bem vindo
          </Typography>
        </div>
        <div style={{
          width: '100%',
          marginTop: 0,
          display: 'flex', alignItems: 'center',
          justifyContent: 'center', flexDirection: 'column'
        }}>
          <img src={Saudacao} style={{
            marginLeft: -30,
            width: 250,
            height: 250,
            alignItems: 'center'
          }} />
        </div>
        <div style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          paddingTop: "10%",
          backgroundColor: '#f6f6f6',
          flex: 1
        }}>
          <Typography variant="h6" gutterBottom align='center' style={{ color: '#484848', fontFamily: 'Montserrat', alignItems: 'center', marginBottom: 50 }}>
            Vamos continuar
          </Typography>
          <Typography variant="h7" gutterBottom component="div" style={{ margin: 20, textAlign: 'center', fontFamily: 'Montserrat' }}>
            Para ter acesso a nossas ofertas pressione o botão continuar, mas antes verifique se sua localização estar ativa
          </Typography>
          <div style={{ marginLeft: 40, paddingRight: 70, marginTop: "15%", alignItems: 'center', justifyItems: 'center' }}>
            <Arredondado label="Continuar" Click={() => {
              this.rotinaLocalizacao(true)
            }} isLoading={this.state.isLoading} />
          </div>
        </div>
      </div>);
  }
}

import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SET_CURRENT_TAB, SET_CART_DEFAULT, ALTERAR_PAGAMENTO } from "./../../redux/actions/";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import './shadon.css'


import Home from './home/home.js'
import Pesquisa from './pesquisa/pesquisa'
import Usuario from './user/usuario'
import Carrinho from './carrinho/carrinho'


class Main extends Component {
  state = {
    tela: 0,
    isKeyboard: false,
  };

  async componentDidMount() {

  }

  render() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
        <div className='scow' style={{ width: '100%', display: 'flex', flex: 1, overflowY: 'scroll' }}>
          {this.renderJanelas()}
        </div>
        <div style={{ display: 'flex', }}>
          {this.rodape()}
        </div>
      </div>
    )
  }

  rodape() {
    var styleBt = {
      width: 40,
      height: 40,
      display: 'flex',
      marginBottom: 0,
      backgroundColor: "white",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };

    const { SET_CURRENT_TAB, SET_CART_DEFAULT, ALTERAR_PAGAMENTO } = this.props;

    return this.state.isKeyboard === false ? (

      <div
        className='sh'
        style={{
          height: 80,
          width: "100%",
          paddingLeft: 40,
          paddingRight: 40,
          marginTop: 20
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
            height: 60,
            justifyContent: "space-between",
          }}
        >
          <div
            style={styleBt}
            onClick={() => {
              SET_CURRENT_TAB(0);
            }}
          >
            <HomeOutlinedIcon
              style={{
                fontSize: 30,
                color:
                  this.props.currentTab.currentTab === 0
                    ? "#7DC8C3"
                    : "#9b9b9b",
              }}
            />
          </div>

          <div
            style={styleBt}
            onClick={() => {
              SET_CURRENT_TAB(1);
            }}
          >
            <SearchOutlinedIcon
              style={{
                fontSize: 30,
                color:
                  this.props.currentTab.currentTab === 1
                    ? "#7DC8C3"
                    : "#9b9b9b",
              }}
            />
          </div>
          <div>
            <div
              style={styleBt}
              onClick={() => {
                if (localStorage.getItem('@foodja_app/usuario_id') > 0) {
                  ALTERAR_PAGAMENTO("");
                  SET_CURRENT_TAB(2);
                } else {
                  if (window.confirm("Neste momento precisamos que você se identifique, faça seu login ou cadastro!")) {
                    this.props.history.push('/app/login');
                  }
                }
              }}
            >
              <ShoppingCartOutlinedIcon
                style={{
                  fontSize: 30,
                  color:
                    this.props.currentTab.currentTab === 2
                      ? "#7DC8C3"
                      : "#9b9b9b",
                }}
              />
            </div>
          </div>

          <div
            style={styleBt}
            onClick={() => {
              if (localStorage.getItem('@foodja_app/usuario_id') > 0) {
                SET_CURRENT_TAB(3);
              } else {
                if (window.confirm("Neste momento precisamos que você se identifique, faça seu login ou cadastro!")) {
                  this.props.history.push('/app/login');
                }
              }
            }}
          >
            <PersonOutlineOutlinedIcon
              style={{
                fontSize: 30,
                color:
                  this.props.currentTab.currentTab === 3
                    ? "#7DC8C3"
                    : "#9b9b9b",
              }}
            />
          </div>
        </div>
      </div>
    ) : null;
  }

  renderJanelas() {
    if (this.props.currentTab.currentTab === 0) {
      return <Home history={this.props.history} />
    } else if (this.props.currentTab.currentTab === 1) {
      return <Pesquisa history={this.props.history} />;
    } else if (this.props.currentTab.currentTab === 2) {
      return <Carrinho history={this.props.history} />;
    } else if (this.props.currentTab.currentTab === 3) {
      return <Usuario history={this.props.history} />;
    } else if (this.props.currentTab.currentTab === 4) {
      //return <Usuario navigation={this.props.navigation} />;
    } else if (this.props.currentTab.currentTab === 5) {
      //return <Endereco navigation={this.props.navigation} />;
    } else if (this.props.currentTab.currentTab === 6) {
      //return <Pedido navigation={this.props.navigation} />;
    }
  }
}

const mapStateToProps = (store) => ({
  currentTab: store.CurrentTabReducer,
  idUsuario: store.CurrentUserReducer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ SET_CURRENT_TAB, SET_CART_DEFAULT, ALTERAR_PAGAMENTO }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);
import React from 'react';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Typography from '@mui/material/Typography';
import { mascaraValor } from './../app/utilites/utilites'

export default function ListPedidos(props) {
  return (
    <div style={{
      display: 'flex',
      width: "100%", height: 80, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: 'white', marginTop: 10,
      borderRadius: 10, borderWidth: 0.5, borderColor: props.padrao ? '#fd5e37' : '#fff'
    }}
      onClick={props.onPress}
    >
      <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
        <div style={{
          width: 65, height: 80, alignItems: 'center',
          justifyContent: 'center', marginLeft: 2
        }}>
          <img
            src={`${props.Link}`}
            srcSet={props.Link}
            alt="Logo"
            loading="lazy"
            style={{
              marginTop: 15,
              width: 55,
              height: 55,
              backgroundColor: '#d8dadc',
              position: 'relative',
              borderRadius: 50,
              marginLeft: 5
            }} />
        </div>
        <div style={{ paddingTop: 10, paddingLeft: 10 }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 800 }}>{(props.loja).substring(0, 17)}</Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400 }}>{props.data}</Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400 }}>{props.status}</Typography>
        </div>
      </div>
      <div>
        <div style={{ paddingRight: 10, paddingTop: 10 }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 400, color: '#fd5e37' }}>R$ {mascaraValor((props.valor).toFixed(2))}</Typography>
        </div>
      </div>
    </div>
  )
}
import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
// import { Container } from './styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QRCode from "react-qr-code";

class PedidoPix extends Component {
  state = {
    pedidos: [],
    isLoading: false,
    isCopy: false
  }

  handleEvent = () => {
    this.setState({ isCopy: true })
    navigator.clipboard.writeText(this.props.location.state.Pedido.url_pix);
  }

  render() {
    return (
      <div style={{ width: '100%', flex: 1, backgroundColor: 'white' }}>
        <div style={{ flex: 1, backgroundColor: '#f8f8f8' }}>
          {this.header()}
          {this.layout()}
        </div>
      </div>)
  }

  layout() {
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.form()
      }
    }
  }

  form() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ width: '95%', flex: 1 }}>
        <div showsHorizontalScrollIndicator={false} ref={(scrolldiv) => { this.scrolldiv = scrolldiv; }}
          horizontal={false}
          decelerationRate={0}
          style={{ paddingLeft: 18, marginTop: 15, width: '90%', flex: 1, paddingRight: 18 }}
        >
          <div style={{ marginTop: 5 }}>
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', fontWeight: '600', }}>Falta pouco para finalizar seu pedido, Utilize seu aplicativo bancário para escanear ou copiar o QR code abaixo e finalizar o pagamento com mais rapidez, via PIX!</Typography>
            <div style={{ width: '100%', marginTop: 35, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <QRCode
                value={this.props.location.state.Pedido.url_pix}
                size={140} />
            </div>

            <div style={{ width: '100%', marginTop: 45, alignItems: 'center', }}>
              <div style={{
                flexDirection: 'row', display: 'flex',
                backgroundColor: '#fd5e37', padding: 15,
                alignItems: 'center', borderRadius: 10
              }} onClick={this.handleEvent}>
                {this.state.isCopy ?
                  <CheckCircleIcon style={{ fontSize: 15, color: '#fff', }} />
                  : null}
                <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fff', fontWeight: '600', }}>Copiar QR-Code</Typography>
              </div>
            </div>

          </div>

        </div>
      </div>
    )
  }

  Loading() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
          <div style={{
            paddingLeft: 18, marginTop: 10, display: 'flex',
            backgroundColor: '#fff', flex: 1, alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{ marginTop: 130, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <CircularProgress style={{ color: '#fd5e37', }} />
              </div>
              <Typography variant="subtitle1" style={{
                fontFamily: 'Montserrat',
                marginTop: 15,
                fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
              }}>Carregando ...</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getEmpresa();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Pagamento Pix
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }
}


const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(Actions, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(PedidoPix);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SET_FILTRO_RED } from './../../../redux/actions/'
import VariaveisGlobais from './../../../utilites/VariaveisGlobais';
import { SET_CURRENT_TAB } from './../../../redux/actions/'
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArticleIcon from '@mui/icons-material/Article';
import Typography from '@mui/material/Typography';
import { mascaraTelefone } from './../../../utilites/utilites';

class Usuario extends Component {

  state = {
    isLoading: false,
    isErro: false,
    isPesquisa: false,
    id_user: 0,
    nome_user: '',
    lag: '',
    lng: '',
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    var lat1 = await localStorage.getItem('@foodja_app/endereco/lat');
    var lng1 = await localStorage.getItem('@foodja_app/endereco/long');
    const user_id = localStorage.getItem('@foodja_app/usuario_id');
    if (localStorage.getItem('@foodja_app/endereco/id') !== undefined || localStorage.getItem('@foodja_app/endereco/id')) {
      await this.setState({
        id_end_user: user_id,
        lag: lat1,
        lng: lng1,
      });
    }

    await this.setState({ id_user: user_id, isLoading: false })

  }

  logoff = () => {
    if (window.confirm("Neste momento precisamos que você se identifique, faça seu login ou cadastro!")) {
      localStorage.setItem('@foodja_app/endereco/id', null);
      localStorage.setItem('@foodja_app/usuario_id', null);
      window.location(window.location.host)
    }
  }

  render() {
    return (
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <div style={{
          paddingLeft: 0, marginTop: 10, display: 'flex',
          backgroundColor: '#f8f8f8', flexDirection: 'column',
        }}>
          {this.header()}
          {this.layout()}
        </div>
      </div>)
  }

  layout() {

    return this.form()

  }

  form() {
    return (
      <div style={{ width: '85%', flex: 1, }}>
        <div className='scow'
          style={{ paddingLeft: 18, marginTop: 15, width: '100%', flex: 1, paddingRight: 18, overflowY: 'scroll' }}
        >
          <Typography variant="subtitle1" gutterBottom align='center' style={{ color: '#fd5e37', fontSize: 12, textAlign: "center", fontWeight: 'bold', color: '#fd5e37' }}>
            Menu / configurações
          </Typography>

          <div style={{ width: 1, height: 15 }} />
          <div
            style={{
              width: "100%", height: 50, backgroundColor: "white", borderRadius: 8,
              paddingLeft: 15, justifyContent: 'space-between', flexDirection: 'row', display: 'flex'
            }}
            onClick={() => {
              this.props.history.push({
                pathname: `/app/endereco`
              });
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex', marginTop: 15 }}>
              <div style={{ width: 30, justifyContent: 'center', }}>
                <FmdGoodIcon style={{ fontSize: 20, color: '#5a5a5a' }} />
              </div>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', }} numberOfLines={1}>
                  Mudar de endereço
                </Typography>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%", height: 50, backgroundColor: "white", borderRadius: 8, display: 'flex',
              paddingLeft: 15, justifyContent: 'space-between', flexDirection: 'row', marginTop: 3
            }}
            onClick={() => {
              this.props.history.push({
                pathname: `/app/pedidos`
              });
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex', marginTop: 15 }}>
              <div style={{ height: 50, width: 30, justifyContent: 'center', display: 'flex', }}>
                <LocalGroceryStoreIcon style={{ fontSize: 20, color: '#5a5a5a' }} />
              </div>
              <div style={{ height: 50, width: '80%', justifyContent: 'center', display: 'flex', }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', }} numberOfLines={1}>
                  Meus pedidos
                </Typography>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%", height: 50, backgroundColor: "white", borderRadius: 8,
              paddingLeft: 15, justifyContent: 'space-between', flexDirection: 'row', marginTop: 3
            }}
            onClick={() => { this.props.navigation.navigate('Carteira'); }}
          >
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ height: 50, width: 30, justifyContent: 'center', display: 'flex', marginTop: 15 }}>
                <AccountBalanceWalletIcon style={{ fontSize: 20, color: '#5a5a5a' }} />
              </div>
              <div style={{ height: 50, width: '80%', justifyContent: 'center', marginTop: 15 }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', }} numberOfLines={1}>
                  Minha carteira
                </Typography>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%", height: 50, backgroundColor: "white", borderRadius: 8,
              paddingLeft: 15, justifyContent: 'space-between', flexDirection: 'row', marginTop: 3
            }} onClick={() => {
              window.open('https://linktr.ee/foodja', '_blank');
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex', }}>
              <div style={{ height: 50, width: 30, justifyContent: 'center', display: 'flex', marginTop: 15 }}>
                <WhatsAppIcon name="whatsapp" type="FontAwesome" style={{ fontSize: 20, color: '#5a5a5a' }} />
              </div>
              <div style={{ height: 50, width: '80%', justifyContent: 'center', marginTop: 15 }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '90%', }} numberOfLines={1}>
                  Suporte
                </Typography>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              window.open('http://foodja.com.br/privacy.html', '_blank');
            }}
            style={{
              width: "100%", height: 50, backgroundColor: "white", borderRadius: 8,
              paddingLeft: 15, justifyContent: 'space-between', flexDirection: 'row', marginTop: 3
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ height: 50, width: 30, justifyContent: 'center', display: 'flex', marginTop: 15 }}>
                <ArticleIcon style={{ fontSize: 20, color: '#5a5a5a' }} />
              </div>
              <div style={{ height: 50, width: '80%', justifyContent: 'center', marginTop: 15 }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', }} numberOfLines={1}>
                  Termo de uso
                </Typography>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', height: 100, alignItems: 'center', paddingTop: 30, display: 'flex', justifyContent: 'center' }}>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, }} numberOfLines={1}>FOODJA TECNOLOGIA LTDA, Manaus / AM
            </Typography>
          </div>

        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ paddingLeft: 20, width: '100%', height: 150, paddingRight: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ backgroundColor: '#f8f8f8', width: 65, height: 65, alignItems: 'center', justifyContent: 'center', borderRadius: 50, display: 'flex' }}>
              <PersonIcon style={{ fontSize: 30, color: '#5a5a5a' }} />
            </div>
            <div style={{ flex: 1, paddingLeft: 20 }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 20, }} numberOfLines={2}>
                {this.props.usuario.nomeUsuario}
              </Typography>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, }} numberOfLines={2}>
                {mascaraTelefone(this.props.usuario.telefoneUsuario)}
              </Typography>
            </div>
          </div>
          <div style={{ height: 150, justifyContent: 'center', alignItems: 'center', width: 80, display: 'flex' }}>
            <div style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}
              onClick={this.logoff}
            >
              <LogoutIcon style={{ fontSize: 20, color: '#fd5e37', marginTop: 10, alignItems: 'center', marginLeft: 20 }} />
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, }} numberOfLines={2}>
                Fazer logoff
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const styleHeader = {
  width: '100%',
  height: 150,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  borderBottomWidth: 0,
  display: 'flex',
}

const mapStateToProps = state => ({
  categorias: state.CategoriasReducer,
  filtro: state.filtroReducer,
  usuario: state.CurrentUserReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_CURRENT_TAB }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Usuario);

import React, { Component } from 'react';

import { SET_CAR_EMP, DEL_ITEM_CAR, ALTERAR_PAGAMENTO, SET_VL_TROCO, LIMPAR_CAR, SET_MY_CARTOES, SET_PAG_ONLINE } from './../../../redux/actions/'
import { mascaraValor } from './../../../utilites/utilites';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ListLojas from './../../../../components/infLoja';
import ListCarrinho from './../../../../components/listCarrinho'
import VariaveisGlobais from './../../../utilites/VariaveisGlobais';
import enviarPedido from './enviarPedido'
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ListEnderecos2, ListEnderecos3, ListEnderecos4 } from './../../../../components/listEnderecos'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import Modal from '@mui/material/Modal';
import InputBase from '@mui/material/InputBase';
import { Quadrado } from './../../../../components/botoes'
import api from './../../../utilites/api';

// import { Container } from './styles';

class Carrinho extends Component {

  state = {
    isLoading: false,
    isErro: false,
    id_end_user: -1,
    loja: {},
    id_user: 0,
    enderecos: [],
    lag: '',
    lng: '',
    pagamento: "",
    vlEntrega: 0,
    vlEntrega_F: 0,
    vlCupon: 0,
    erroEnd: false,
    pagamento: '',
    mdPagamento: false,
    mdTroco: false,
    mdCupom: false,
    isLoadCupom: false,
    isEnviando: false,
    vlTroco: '',
    observacao: '',
    cupom: '',
    cupom2: '',
    pDesc: 0,
    vlCarteira: 0,
    appCofings: {},
    desconto_loja: 0
  }

  async componentDidMount() {
    const { ALTERAR_PAGAMENTO, SET_VL_TROCO, SET_PAG_ONLINE } = this.props
    if (this.props.myCarts.default !== null) {
      console.log("Oii: ", this.props.myCarts.default)
    } else {
      //ALTERAR_PAGAMENTO("");
    }

    SET_VL_TROCO("");
    SET_PAG_ONLINE(0);

    this.setState({ isLoading: true });

    await this.setState({
      lag: await localStorage.getItem('@foodja_app/endereco/lat'),
      lng: await localStorage.getItem('@foodja_app/endereco/long'),
      id_user: await localStorage.getItem('@foodja_app/usuario_id')
    })

    this.getServidor();


  }

  async getServidor() {

    this.setState({ isLoading: true, isErro: false })
    try {
      const data = new FormData();
      data.append('lat', this.state.lag);
      data.append('lng', this.state.lng);
      data.append('user', this.state.id_user);
      data.append('iduser', this.state.id_user);
      data.append('id_Emp', this.props.carrinho.empresaCarrinho);

      const enderecos = await api.get(`getEnderecos/${this.state.id_user}/${this.props.carrinho.empresaCarrinho}`);
      const configsApp = await api.get(`getConfigs`);
      const infLoja = await api.post(`getLoja`, data);
      const cartoes = await api.get(`getCartoes/${this.state.id_user}`);
      const saldoCarteira = await api.post(`user/saldoCart`, data);


      console.log('aqui: ', enderecos.data)
      console.log('loja: ', infLoja.data.data)
      if (enderecos.status === 200) {
        await this.setState({
          enderecos: enderecos.data.data,
          loja: infLoja.data.data[0],
          vlCarteira: saldoCarteira.data.saldo
        });
        if (Object.entries(this.state.enderecos).length > 0) {
          var temp = null;
          var temp2 = [];
          var temp3 = this.state.enderecos;
          var temp4 = 0;
          for (var i = 0; i < Object.entries(this.state.enderecos).length; i++) {
            if (this.state.enderecos[i].id === this.state.id_end_user) {
              temp = this.state.enderecos[i];
              this.setState({
                vlEntrega: infLoja.data.data[0].entrega_free === 1 ? 0 : this.state.enderecos[i].vl_Entrega,
                vlEntrega_F: this.state.enderecos[i].vl_Entrega
              })
              temp4 = i
            }
          }

          if (this.state.id_end_user !== -1) {
            temp3.splice(temp4, 1);
            temp2.push(temp);
          }

          for (var i = 0; i < Object.entries(temp3).length; i++) {
            temp2.push(temp3[i]);
          }

          const { SET_MY_CARTOES } = this.props

          SET_MY_CARTOES(cartoes.data.data);

          if (this.somarPromo() > 0) {
            var vlDesc = ((this.somarCarrinho()) * this.somarPromo()) / 100;
            this.setState({ vlCupon: vlDesc, pDesc: this.somarPromo(), desconto_loja: 1 })
          }

          await this.setState({ enderecos: temp2 });
        }

        await this.setState({ isLoading: false, appCofings: configsApp.data.data })
      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }

    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }

  }

  async excluirItem(key) {
    if (window.confirm("Deseja mesmo retirar o produto do carrinho?")) {
      const { DEL_ITEM_CAR } = this.props;

      await DEL_ITEM_CAR(key);
      if (this.somarPromo() > 0) {
        var vlDesc = ((this.somarCarrinho()) * this.somarPromo()) / 100;
        this.setState({ vlCupon: vlDesc, pDesc: this.somarPromo(), desconto_loja: 1 })
      }
    }
  }

  somarPromo() {
    var vl = 0.00;
    this.props.carrinho.carrinho.map(i => (
      vl = eval(parseFloat(vl) + parseFloat(i.valor_desc))
    ))
    vl = vl / this.props.carrinho.carrinho.length;
    return vl.toFixed(1);
  }

  async retirarda() {
    this.setState({ id_end_user: null, vlEntrega: 0 })
  }

  async alteraEnd(i) {
    if (i.distancia <= this.state.loja.raio) {
      await localStorage.setItem('@foodja_app/endereco/id', i.id);
      await localStorage.setItem('@foodja_app/endereco/nome', i.endereco);
      await localStorage.setItem('@foodja_app/endereco/long', i.longitude);
      await localStorage.setItem('@foodja_app/endereco/lat', i.latitude);

      this.setState({ id_end_user: i.id, vlEntrega: i.vl_Entrega });

    } else {
      alert(`${this.state.loja.nome} não atende o endereço selecionado!`, 'Atenção', false);
    }
  }

  somarCarrinho() {
    var vl = 0.00;
    var adc = 0.00

    this.props.carrinho.carrinho.map(i => (
      vl = eval(parseFloat(vl) + parseFloat(i.total))
    ))
    vl = vl;


    return vl;
  }

  render() {
    return (
      <div style={{ width: '100%', flex: 1, backgroundColor: 'white' }}>
        {this.header()}
        {this.layout()}
      </div>
    );
  }

  layout() {
    if (Object.entries(this.props.carrinho.carrinho).length > 0) {
      if (this.state.isLoading) {
        return this.Loading()
      } else {
        if (this.state.isErro) {
          return this.noConnection()
        } else {
          return this.form()
        }
      }

    } else {
      return this.emptyCar()
    }
  }

  form() {
    const vglobais = new VariaveisGlobais();
    return (
      <div className='scow' style={{ overflowY: 'scroll', width: '99%', flex: 1, backgroundColor: 'white' }}      >
        <div style={{ width: '95%' }}>
          {this.mdCadastro()}
          <div>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>Entregar em: </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div
              className='scow'
              style={{
                display: 'flex', flexDirection: 'row', marginTop: 6,
                marginLeft: 0, height: 100, paddingBottom: 5, paddingLeft: 0, overflowY: 'auto'
              }}
            >
              <ListEnderecos4 key={-1}
                titulo="Retirar na loja"
                padrao={null === this.state.id_end_user ? true : false}
                onPress={() => { this.retirarda() }}
              />
              {this.state.enderecos.map((i, key) => (
                <ListEnderecos2 key={key}
                  titulo={i.titulo} endereco={`${i.endereco}, ${i.numero}, ${i.bairro}`}
                  padrao={i.id === this.state.id_end_user ? true : false}
                  onPress={() => { this.alteraEnd(i) }}
                />
              ))}
              {Object.entries(this.state.enderecos).length === 0 ?
                <ListEnderecos3
                  onPress={async () => {
                    this.props.navigation.navigate('Endereco', {
                      abrirCadastro: true
                    });
                  }}
                />
                : null}
              <div style={{ width: 100, height: 20 }} />
            </div>
            {Object.entries(this.state.enderecos).length === 0 ? null :
              <div style={{ display: 'flex', height: 60, marginTop: 10, alignItems: 'center', justifyContent: 'center', marginLeft: 15 }}>
                <div style={{ display: 'flex', backgroundColor: '#fd5e37', height: 40, width: 40, borderRadius: 50, marginTop: -15, justifyContent: 'center', alignItems: 'center' }}
                  onClick={async () => {
                    this.props.history.push({
                      pathname: `/app/endereco`
                    });
                  }}
                >
                  <AddIcon style={{ color: '#fff', fontSize: 18 }} />
                </div>
              </div>
            }
          </div>


          <div style={{ marginTop: 5 }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>Itens do carrinho: </Typography>
          </div>
          <div style={{ width: '100%', marginTop: 5, height: 230 }}>
            <div
              className='scow'
              style={{
                display: 'flex', flexDirection: 'row', marginTop: 6,
                marginLeft: 0, height: 150, paddingBottom: 5, paddingLeft: 0, overflowY: 'auto'
              }}
            >
              {this.props.carrinho.carrinho.map((i, key) => (
                <ListCarrinho key={key}
                  Link={`${vglobais.getHostImg}/${i.imagem_nome}`}
                  Nome={i.nome} Preco={i.total} Qtd={i.quantidade}
                  onPress={() => { this.excluirItem(key) }}
                />
              ))}
              <div style={{ width: 100, height: 20 }} />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <ListLojas Link={`${vglobais.getHostImg}/${this.state.loja.name_img}`}
                Nome={this.state.loja.nome} End={this.state.loja.endereco}
                onPress={() => {
                  this.props.history.push({
                    pathname: `/app/loja/${this.state.loja.id}`
                  });
                }} />
            </div>
          </div>
          <div style={{ width: '100%', marginTop: 10 }}>
            <div style={{ display: 'flex', width: '100%', flexDirection: "row", backgroundColor: 'white', padding: 5, borderRadius: 10, justifyContent: 'space-between' }}
              onClick={() => {
                this.props.history.push({
                  pathname: '/app/pagamento',
                  state: {
                    vlCarrinho: (((this.somarCarrinho() + this.state.vlEntrega) - this.state.vlCupon).toFixed(2)),
                    vlCarteira: this.state.vlCarteira,
                    appConfig: this.state.appCofings
                  }
                });
              }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: 40, height: 40, backgroundColor: '#fd5e37', justifyContent: 'center', alignItems: 'center', borderRadius: 50 }}>
                  <AttachMoneyIcon style={{ fontSize: 18, color: '#fff' }} />
                </div>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, marginLeft: 15 }}>{this.props.carrinho.tipoPagamento === "" ? "Adcionar pagamento" : "Pagamento: "} {this.props.carrinho.tipoPagamento}</Typography>
              </div>
              <div style={{ width: 90, justifyContent: 'center' }}>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, textAlign: 'right' }}>Alterar</Typography>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', backgroundColor: 'white', marginTop: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10 }}>
            <div style={{
              width: '100%', flexDirection: "row",
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between', height: 40
            }}
              onClick={() => {
                if (this.somarPromo() > 0) {
                  alert('Promoção aplicada pelo estabelecimento, não suporta cupom de desconto', "Atenção", false)
                } else {
                  this.setState({ mdCupom: true })
                  //alert('Promoção aplicada pelo estabelecimento, não suporta cupom de desconto', "Atenção", false)

                }
              }}
            >
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>Cupom de desconto: </Typography>
              <div style={{ width: 90 }}>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, textAlign: 'right', color: '#fd5e37' }}>{!this.state.pDesc > 0 ? 'Adicionar' : this.state.cupom + ` (-${this.state.pDesc}%)`}</Typography>
              </div>
            </div>



            <div style={{ width: '100%', height: 2, borderBottomWidth: 1, borderBottomColor: '#e9e9e9' }} />

            <div style={{ display: 'flex', width: '100%', flexDirection: "row", justifyContent: 'space-between', marginTop: 15 }}>

              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>Valor dos itens: </Typography>
              <div>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>R$ {mascaraValor(this.somarCarrinho().toFixed(2))}</Typography>
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', flexDirection: "row", justifyContent: 'space-between', marginTop: 5 }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>Valor da entrega: </Typography>
              <div>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>R$ {mascaraValor(this.state.vlEntrega.toFixed(2))}</Typography>
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', flexDirection: "row", justifyContent: 'space-between', marginTop: 5 }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>Desconto: </Typography>
              <div>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>R$ {mascaraValor(this.state.vlCupon.toFixed(2))}</Typography>
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', flexDirection: "row", justifyContent: 'space-between', marginTop: 5 }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}>Total: </Typography>
              <div>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, }}> R$ {mascaraValor(((this.somarCarrinho() + this.state.vlEntrega) - this.state.vlCupon).toFixed(2))}</Typography>
              </div>
            </div>

            <div style={{
              width: '100%', height: 50, display: 'flex',
              backgroundColor: '#fd5e37', marginTop: 25, marginBottom: 25, borderRadius: 8,
              alignItems: 'center', justifyContent: 'center'
            }}
              disabled={this.state.isEnviando}
              onClick={async () => {
                var valid = true;
                var msg = "";

                const { LIMPAR_CAR } = this.props

                if (this.state.id_end_user < 1 && this.state.id_end_user !== null) {
                  valid = false;
                  msg = msg + " > Selecione um endereço de entrega! \n";
                }

                if (this.props.carrinho.tipoPagamento === "") {
                  valid = false;
                  msg = msg + " > Selecione uma forma de pagamento! \n";
                }

                if (this.state.loja.is_aberto !== "Aberto") {
                  valid = false;
                  msg = msg + " > O estabelecimento está fechado no momento! \n";
                }

                if (this.props.carrinho.tipoPagamento === "Carteira on-line" && this.props.carrinho.pagCarteira < ((this.somarCarrinho() + this.state.vlEntrega) - this.state.vlCupon)) {
                  valid = false;
                  console.log('Pedido: ', ((this.somarCarrinho() + this.state.vlEntrega) - this.state.vlCupon))
                  console.log('Carteira: ', this.props.carrinho.pagCarteira)
                  msg = msg + " > Saldo da carteira e menor que o valor do pedido! \n";
                }

                if (this.somarCarrinho() < this.state.loja.pedido_minimo) {
                  valid = false;
                  msg = msg + ` > O valor minimo de pedido nesta loja e de: R$! ${mascaraValor((this.state.loja.pedido_minimo).toFixed(2))}\n`;
                }

                if (this.state.appCofings.open_app === 0) {
                  valid = false;
                  msg = msg + ` > Nosso serviço está indisponível no momento, tente mais tarde`;
                }

                if (this.state.appCofings.open_app === 3) {
                  valid = false;
                  msg = msg + ` > Nosso serviço está congestionado no momento, tente mais tarde`;
                }

                if (valid) {
                  var ok = false;
                  this.setState({ isEnviando: true })
                  console.log("1", ok)
                  ok = await enviarPedido(
                    this.state,
                    ((this.somarCarrinho() + this.state.vlEntrega) - this.state.vlCupon),
                    this.somarCarrinho(),
                    this.state.vlEntrega,
                    this.state.vlCupon,
                    this.props.carrinho.carrinho,
                    this.props.carrinho.tipoPagamento,
                    this.props.carrinho.carrinho,
                    this.props.myCarts.default,
                    this.state.cupom,
                    this.props.carrinho.pagCarteira,
                    this.state.desconto_loja,
                    this.props,
                    this.state.vlEntrega_F,
                  )
                  this.setState({ isEnviando: false })
                  console.log("2", ok)
                  if (ok) {
                    LIMPAR_CAR("")
                  }

                } else {
                  alert(msg)
                }
              }}
            >
              {this.state.isEnviando ?
                <Typography variant="subtitle1" style={{ color: '#fff', width: '100%', textAlign: "center", fontFamily: 'Montserrat', fontSize: 14, fontWeight: 'bold', }}>Enviando ....</Typography>
                :
                <Typography variant="subtitle1" style={{ color: '#fff', width: '100%', textAlign: "center", fontFamily: 'Montserrat', fontSize: 14, fontWeight: 'bold', }}>Finalizar pedido</Typography>


              }
            </div>

          </div>

          <div style={{ width: 10, height: 120 }} />

        </div>

      </div>
    );
  }

  Loading() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
          <div style={{
            paddingLeft: 18, marginTop: 10, display: 'flex',
            backgroundColor: '#fff', flex: 1, alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{ marginTop: 130, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <CircularProgress style={{ color: '#fd5e37', }} />
              </div>
              <Typography variant="subtitle1" style={{
                fontFamily: 'Montserrat',
                marginTop: 15,
                fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
              }}>Carregando ...</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getEmpresa();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  emptyCar() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <RemoveShoppingCartIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Seu carrinho está vázio!!</Typography>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Carrinho
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }

  mdCadastro() {
    return (
      <Modal
        animationType="fade"
        open={this.state.mdCupom}
        onClose={() => {
          this.setState({ mdCupom: false })
        }}>

        <div style={{ backgroundColor: '#ffffff', borderRadius: 10, marginTop: 50, }}>
          <div style={{ marginTop: 15, marginLeft: 25, height: 15 }}>
            {/* <TouchableOpacity style={{ height: 40, width: 40 }} onPress={() => { this.setState({ mdCadastro: false }) }}>
                <Icon style={{ color: "#8b8a8a", fontSize: 18 }} type="MaterialIcons" name="close" />
              </TouchableOpacity> */}
          </div>
          <div showsVerticalScrollIndicator={false} style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div style={{ marginTop: 20, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6", height: 80 }}>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Cupom de desconto:</Typography>
                <InputBase
                  value={this.state.cupom}
                  style={{
                    fontFamily: 'Montserrat', fontSize: 13, marginTop: 20
                  }}
                  disabled={this.state.isLoading}
                  onChange={cupom => this.setState({ cupom: cupom.target.value.toString() })}
                />
              </div>

            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 25 }}>
              <Quadrado
                label="Continuar"
                isLoading={this.state.isLoadCupom}
                onPress={async () => {
                  this.setState({ isLoadCupom: true })

                  const data = new FormData();
                  data.append('titulo', this.state.cupom);
                  data.append('id_usuario', this.state.id_user);

                  try {
                    const response = await api.post('checaCupom', data);
                    if (response.status === 200) {
                      if (response.data.disponivel) {
                        var vlDesc = ((this.somarCarrinho() + this.state.vlEntrega) * response.data.valor) / 100;
                        this.setState({ vlCupon: vlDesc, mdCupom: false, pDesc: response.data.valor })
                      } else {
                        alert(`${response.data.message}`)
                      }
                    }
                  } catch (Err) {
                    alert(`Erro na comunicação com o servidor`)
                  }
                  this.setState({ isLoadCupom: false })
                }} />
              <div style={{ width: 10, height: 10 }} />
              <div style={{ width: 15, height: 45 }} />
            </div>
          </div>
        </div>
      </Modal >
    );
  }

}


const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = state => ({
  carrinho: state.CarrinhoReducer,
  myCarts: state.CurrentCartoesReducer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_CAR_EMP, DEL_ITEM_CAR, ALTERAR_PAGAMENTO, SET_VL_TROCO, LIMPAR_CAR, SET_MY_CARTOES, SET_PAG_ONLINE }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Carrinho);

import React, { Component } from 'react';
import Logo from './../../../assets/logo.png'
import CircularProgress from '@mui/material/CircularProgress';
// import { Container } from './styles';
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import api from './../../utilites/api'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SET_USUARIO_LOG, SET_INF_USUARIO_LOG, SET_CURRENT_TAB } from './../../redux/actions/';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import BemVindo from './../bemvindo/bemvindo'
import Main from './../principal/main'
import InfLojas from './../loja/infoLoja'
import Produto from './../produto/produto'
import Login from './../login/login'
import Enderecos from './../endereco/endereco'
import Pedidos from './../pedidos/pedidos'
import Detalhe_pedido from './../pedidos/Detalhepedidos'
import Pagamento from './../pagamento/pagamento'
import PedidoPix from './../pedidoPix/pedidoPix'

class Splash extends Component {
  state = {
    isLoading: true,
    isErro: false
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    }, () => {
      console.log('No location!!!');
    });
    this.getServidor();
  }

  async getServidor() {
    const {
      SET_USUARIO_LOG, SET_INF_USUARIO_LOG
    } = this.props;
    await this.setState({ isLoading: true, isErro: false });

    try {
      const response = await api.get('statusApp');
      //console.log('configs: ', response.data)
      if (response.status === 200) {
        var cc1 = true;
        const endereco_user = localStorage.getItem('@foodja_app/endereco/id');
        const user_id = localStorage.getItem('@foodja_app/usuario_id');

        if (endereco_user >= -1) {
          cc1 = false;
        }

        if(endereco_user === null) {
          cc1 = true;
        }

        if (!cc1) {
          var cont = false;
          if (user_id !== undefined || user_id !== null) {
            var id = 0;
            cont = true;
            id = user_id
            const data = new FormData();

            data.append('id', id);
            data.append('token_app', '');
            const inf_User = await api.post('infoUser', data);
            if (inf_User.status === 200) {
              await SET_INF_USUARIO_LOG(inf_User.data.data)
            } else {
              alert('Erro na comunicação com o servidor!');
              this.setState({ isLoading: false, isErro: true })
              cont = false
            }
          }
          localStorage.setItem('@foodja_app/path_name', window.location.pathname);
          if (cont) {
            await SET_USUARIO_LOG(id);
            this.setState({ isLoading: false })
            var path = localStorage.getItem('@foodja_app/path_name').includes('/loja/') ? localStorage.getItem('@foodja_app/path_name') : '/app/home';

            //alert(path)
            this.props.history.push(path);
          } else {
            var path = localStorage.getItem('@foodja_app/path_name').includes('/loja/') ? localStorage.getItem('@foodja_app/path_name') : '/app/home';
            //alert(path)
            this.props.history.push(path);
          }

        } else {
          localStorage.setItem('@foodja_app/path_name', window.location.pathname);
          this.setState({ isLoading: false })
          this.props.history.push('/app/bem-vindo');
        }

      } else {
        alert('Erro na comunicação com o servidor!');
        this.setState({ isLoading: false, isErro: true })
      }

    } catch (error) {
      console.log(error)
      alert('Erro na comunicação com o servidor!');
      this.setState({ isLoading: false, isErro: true })
    }
  }

  render() {
    return this.state.isLoading ? this.splash() : this.aplicacao()
  }

  aplicacao() {
    console.log('URL:::::::: ', window.location.href)
    console.log('URL:::::::: ')
    return (
      <Switch>
        <Route exact path="/app/bem-vindo" component={BemVindo} />
        <Route exact path="/app/home" component={Main} />
        <Route exact path="/app/loja/:id" component={InfLojas} />
        <Route exact path="/app/produto/:id" component={Produto} />
        <Route exact path="/app/login" component={Login} />
        <Route exact path="/app/endereco" component={Enderecos} />
        <Route exact path="/app/pedidos" component={Pedidos} />
        <Route exact path="/app/pedidos_detalhe" component={Detalhe_pedido} />
        <Route exact path="/app/pagamento" component={Pagamento} />
        <Route exact path="/app/pedidoPix" component={PedidoPix} />
      </Switch>
    );
  }

  splash() {
    return (
      <div style={{
        width: '100%', height: '100vh',
        backgroundColor: '#fd5e37', display: 'flex', alignItems: 'center',
        justifyContent: 'center', flexDirection: 'column'
      }}>
        <img src={Logo} width={300} />
        <CircularProgress style={{ color: '#fff', marginTop: 35 }} />
      </div>
    );
  }
}


const mapStateToProps = store => ({
  currentTab: store.CurrentTabReducer,
  idUsuario: store.CurrentUserReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_USUARIO_LOG, SET_INF_USUARIO_LOG }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Splash);
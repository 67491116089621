import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Arredondado2 } from './../../../components/botoes'
import ListEnderecos from './../../../components/listEnderecos'
import Geocode from "react-geocode";
import api from './../../utilites/api'
import Typography from '@mui/material/Typography';
import { SET_CURRENT_TAB } from './../../redux/actions/'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Modal from '@mui/material/Modal';
import InputBase from '@mui/material/InputBase';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// import { Container } from './styles';

class Endereco extends Component {

  state = {
    enderecos: [],
    isLoading: false,
    isLoading2: false,
    isLoading3: false,
    isErro: false,
    id_user: 0,
    id_end_user: 0,
    endereco: '',
    rua: '',
    numero: '',
    latitude: '',
    longitude: '',
    complemento: '',
    nomeEnd: '',
    bairro: '',
    PermissaoLocation: false,
    mdCadastro: false
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    console.log("Latitude is :");
    const user_id = localStorage.getItem('@foodja_app/usuario_id');
    var id_end = await localStorage.getItem('@foodja_app/endereco/id');
    if (localStorage.getItem('@foodja_app/endereco/id') !== undefined) {
      await this.setState({
        id_user: user_id,
        id_end_user: id_end
      });
    }

    await this.rotinaLocalizacao(false)
    this.getServidor();

  }

  async rotinaLocalizacao(localizar) {
    this.setState({ isLoading: true });

    if (navigator.geolocation) {
      //navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({ PermissaoLocation: true });
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    }, () => {
      this.setState({ PermissaoLocation: false });
      console.log('No location!!!');
    });

    if (localizar) {
      this.getCoordinates();
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  async getCoordinates() {
    console.log("ok")
    this.setState({ isLoading: true })

    Geocode.setApiKey("AIzaSyCFOW4NYmjJwSBbkkcbvB2tg4k0ZGkFTFk");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    Geocode.setLocationType("ROOFTOP");

    navigator.geolocation.getCurrentPosition((position) => {

      this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        isLoading2: false
      });

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log();

          this.setState({
            endereco: response.results[0].formatted_address,
            rua: '',
            numero: '',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            bairro: '',
            mdCadastro: true
          });

        },
        (error) => {
          alert('Erro ao obter localização, tente novamente!');
          this.setState({ isLoading: false });
          console.log('Erro: ', error)
        }
      );

    }, () => {
      this.setState({ PermissaoLocation: false });
      alert('Erro ao obter localização, tente novamente!');
      console.log('No location!!!');
    });

  }

  async getServidor() {

    this.setState({ isLoading: true, isErro: false })
    try {
      const enderecos = await api.get(`getEnderecos/${this.state.id_user}`);
      console.log('aqui: ', enderecos.data)
      if (enderecos.status === 200) {
        await this.setState({
          enderecos: enderecos.data.data,
        });

        if (Object.entries(this.state.enderecos).length === 0) {
          await this.rotinaLocalizacao(true);
        }

        this.setState({ isLoading: false })
      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }

    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }

  }

  handleSetEnd(i) {

    localStorage.setItem('@foodja_app/endereco/id', i.id);
    localStorage.setItem('@foodja_app/endereco/nome', i.endereco);
    localStorage.setItem('@foodja_app/endereco/long', i.longitude);
    localStorage.setItem('@foodja_app/endereco/lat', i.latitude);

    this.props.history.push('/');
  }

  render() {
    return (
      <div style={{ width: '100%', flex: 1, backgroundColor: 'white' }}>
        <div style={{ flex: 1, backgroundColor: '#fff', paddingLeft: 10, paddingRight: 10 }}>
          {this.header()}
          {this.layout()}
        </div>
      </div>)
  }

  mdCadastro() {
    return (
      <Modal
        animationType="fade"
        open={this.state.mdCadastro}
        onClose={() => {
          this.setState({ mdCadastro: false })
        }}>
        <div style={{ flex: 1, marginTop: 0, paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ marginTop: 90, backgroundColor: '#ffffff', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            <div style={{ marginTop: 40, marginLeft: 25 }}>
              <div style={{ height: 40, width: 40, marginTop: 20 }} onPress={() => { this.setState({ mdCadastro: false }) }}>
                <CloseOutlinedIcon style={{ color: "#8b8a8a", fontSize: 30 }} />
              </div>
              <div showsVerticalScrollIndicator={false} style={{ marginLeft: 20, marginRight: 20 }}>
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <div style={{ marginTop: 5, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Nome* (Ex:. Minha casa, trabalho ...)</Typography>
                    <InputBase
                      value={this.state.nomeEnd}
                      style={{
                        fontFamily: 'Montserrat', fontSize: 13, height: 40
                      }}
                      disabled={this.state.isLoading}
                      onChange={nomeEnd => this.setState({ cpfCadastro: nomeEnd.target.value.toString() })}
                    />
                  </div>

                  <div style={{ marginTop: 10, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Rua</Typography>
                    <InputBase
                      value={this.state.rua}
                      style={{
                        fontFamily: 'Montserrat', fontSize: 13, height: 40
                      }}
                      disabled={this.state.isLoading}
                      onChange={rua => this.setState({ rua: rua.target.value.toString() })}
                    />
                  </div>
                  <div style={{ marginTop: 10, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Numero</Typography>
                    <InputBase
                      value={this.state.numero}
                      style={{
                        fontFamily: 'Montserrat', fontSize: 13, height: 40
                      }}
                      disabled={this.state.isLoading}
                      onChange={numero => this.setState({ numero: numero.target.value.toString() })}
                    />
                  </div>
                  <div style={{ marginTop: 10, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>                    
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Bairro</Typography>
                    <InputBase
                      value={this.state.bairro}
                      style={{
                        fontFamily: 'Montserrat', fontSize: 13, height: 40
                      }}
                      disabled={this.state.isLoading}
                      onChange={bairro => this.setState({ bairro: bairro.target.value.toString() })}
                    />
                  </div>
                  <div style={{ marginTop: 10, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>                    
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Complemento (Ex:. Cor da casa, referência, apartamento...)</Typography>
                    <InputBase
                      value={this.state.complemento}
                      style={{
                        fontFamily: 'Montserrat', fontSize: 13, height: 70
                      }}
                      disabled={this.state.isLoading}
                      onChange={complemento => this.setState({ complemento: complemento.target.value.toString() })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>)
  }

  form() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        {this.mdCadastro()}
        <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: 'white' }}>
          <div style={{ marginTop: 25 }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, }}>Resultados ({this.state.enderecos.length})</Typography>
          </div>
          <div style={{ marginTop: 5, paddingLeft: 5, paddingRight: 8 }}>
            {this.state.enderecos.map((i, key) => (
              <ListEnderecos titulo={i.titulo}
                endereco={`${i.rua}, ${i.numero}, ${i.bairro}`} key={key}
                obs={i.complemento}
                padrao={this.state.id_end_user === i.id ? true : false}
                onPress={async () => { this.handleSetEnd(i) }}
              />
            ))}
          </div>

        </div>
        <div style={{ paddingLeft: 20, paddingRight: 45, marginBottom: 50 }}>
          <Arredondado2
            label="Cadastrar novo endereço"
            isLoading={this.state.isLoading2}
            onPress={() => {
              this.rotinaLocalizacao(true);
            }}
          />
        </div>
      </div>
    )
  }

  layout() {
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.form()
      }
    }
  }

  Loading() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
          <div style={{
            paddingLeft: 18, marginTop: 10, display: 'flex',
            backgroundColor: '#fff', flex: 1, alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{ marginTop: 130, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <CircularProgress style={{ color: '#fd5e37', }} />
              </div>
              <Typography variant="subtitle1" style={{
                fontFamily: 'Montserrat',
                marginTop: 15,
                fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
              }}>Carregando ...</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getEmpresa();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Endereço
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }
}

const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = store => ({
  currentTab: store.CurrentTabReducer,
  idUsuario: store.CurrentUserReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_CURRENT_TAB }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Endereco);

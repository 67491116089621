const state = {
  categorias: []
};

export const CategoriasReducer = (stateAt = state, action) => {
  switch (action.type) {
    case 'SET_CATEGORIAS':
      return {
        ...stateAt,
        categorias: action.categorias
      };
    default:
      return stateAt;
  }
};
import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { mascaraValor } from './../app/utilites/utilites'
import Typography from '@mui/material/Typography';

export default function ListCarrinho(props) {
  return (
    <div className='sh2' style={{
      height: 150, width: 180, backgroundColor: 'WHITE', borderRadius: 10, marginTop: 15, marginRight: 18, shadowColor: '#adadad',
      shadowOpacity: 0.5, elevation: 5, shadowOffset: { width: 1, height: 1 }, marginTop: 10, marginLeft: 5, marginBottom: 5
    }}>
      <div style={{ width: "100%", alignItems: 'flex-end', }}>
        <div style={{
          display: 'flex',
          padding: 5, alignItems: 'center', justifyContent: 'center',
          backgroundColor: '#fd5e37', borderRadius: 50, width: 15, height: 15, marginTop: -8, marginRight: -8
        }} onClick={props.onPress}>
          <DeleteOutlineIcon style={{ fontSize: 13, color: 'white' }} />
        </div>
      </div>
      <div style={{ display: 'flex', width: "100%", alignItems: 'center', marginTop: -5, justifyContent: 'center' }}>
        <img
          src={`${props.Link}`}
          srcSet={props.Link}
          alt="Logo"
          loading="lazy"
          style={{
            marginTop: 0,
            width: 45,
            height: 45,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 50
          }} />
      </div>
      <div style={{ width: '100%', paddingRight: 10, marginTop: 10 }}>
        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, width: '100%', textAlign: 'center' }} numberOfLines={2}>{props.Nome}
        </Typography>
      </div>

      <div style={{ width: '100%', paddingLeft: 10, paddingRight: 10, marginTop: 0, height: 40, }}>

      </div>

      <div style={{
        display: 'flex',
        width: '85%', paddingLeft: 10, paddingRight: 10,
        marginTop: 0, flexDirection: "row", justifyContent: 'space-between'
      }}>
        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', color: "#fd5e37", fontSize: 12 }} numberOfLines={2}>
          Qtd: {props.Qtd}
        </Typography>

        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', color: "#fd5e37", fontSize: 12 }} numberOfLines={2}>
          R$ {mascaraValor((props.Preco).toFixed(2))}
        </Typography>
      </div>

    </div>
  );
}
const serve = 'https://backend.fuxikoja.com.br';
//const serve        = 'http://192.168.3.4:3333';
//const serveIMG     = 'https://foodja.foodja-back1.com.br/getImage'
const serveIMG = 'https://backend.fuxikoja.com.br/getImage'
const onesignal = '50469dfd-400a-4747-b69b-6ecdbb093250';


//contato@foodja.com.br
//sucesso_foodja

export default class VariaveisGlobais {
    get getHost() {
        return serve;
    }

    get getOneSignal() {
        return onesignal;
    }

    get getHostImg() {
        return serveIMG;
    }
}
const state = {
  default: null,
  myCartoes: []
}

export const CurrentCartoesReducer = (stateAt = state, action) => {
  switch (action.type) {
    case 'SET_CART_DEFAULT':
      return {
        ...stateAt,
        default: action.obj
      };
    case 'SET_MY_CARTOES':
      return {
        ...stateAt,
        myCartoes: action.obj
      };
    default:
      return stateAt;
  }
}
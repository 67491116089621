import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export function Arredondado(props) {
  return (
    <div
      style={{
        width: '100%',
        borderColor: '#fd5e37',
        borderStyle: 'solid',
        borderWidth: props.isLoading ? 0 : 1,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        borderRadius: 40,
        minHeight: 40,
      }}
      onClick={props.Click}
      disabled={props.isLoading}
    >
      {props.isLoading ?
        <div style={{
          display: 'flex', alignItems: 'center',
          justifyContent: 'center', flexDirection: 'column'
        }}>
          <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fff' }}>
            Carregando ....
          </Typography>
        </div>
        :
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fd5e37' }}>
          {props.label}
        </Typography>
      }

    </div>
  )
}

export function Arredondado2(props) {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#fd5e37',
        padding: 15,
        borderRadius: 40,
        minHeight: 50,
        display: 'flex',
        alignItems: 'center'
      }}
      onClick={props.onPress}
      disabled={props.isLoading}
    >
      {props.isLoading ?
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fff' }}>
          Carregando ....
        </Typography>
        :
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fff' }}>
          {props.label}
        </Typography>

      }

    </div>
  )
}

export function Quadrado(props) {
  return (
    <div
      style={{
        width: '80%',
        backgroundColor: '#fd5e37',
        padding: 15,
        borderRadius: 8,
        minHeight: 30,
        display: 'flex',
        alignItems: 'center'
      }}
      onClick={props.onPress}
      disabled={props.isLoading}
    >
      {props.isLoading ?
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fff' }}>
          Carregando ....
        </Typography>
        :
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fff' }}>
          {props.label}
        </Typography>

      }

    </div>
  )
}

export function Quadrado2(props) {
  return (
    <div
      style={{
        width: '80%',
        backgroundColor: '#fff',
        padding: 15,
        borderRadius: 8,
        minHeight: 50,
        borderColor: '#fd5e37',
        borderWidth: 0.5,
        display: 'flex',
        alignItems: 'center'
      }}
      onClick={props.onPress}
      disabled={props.isLoading}
    >
      {props.isLoading ?
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fd5e37' }}>
          Carregando ....
        </Typography>
        :
        <Typography variant="subtitle1" gutterBottom align='center' style={{ width: '100%', textAlign: "center", fontWeight: 'bold', color: '#fd5e37' }}>
          {props.label}
        </Typography>

      }

    </div>
  )
}
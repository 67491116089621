import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mascaraValor } from './../../utilites/utilites';
import { ALTERAR_PAGAMENTO, SET_VL_TROCO, SET_MY_CARTOES, SET_CART_DEFAULT, SET_PAG_ONLINE } from './../../redux/actions/'
import ListPagamento, { ListPagamentoPix } from './../../../components/listPagamento'
import { Quadrado, Quadrado2 } from './../../../components/botoes';
import { mascaraCartao, bandeira } from './../../utilites/utilites'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CurrencyInput from 'react-currency-input';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

// import { Container } from './styles';

class Pagamento extends Component {

  state = {
    pagamentos: [{
      id: 1, nome: "Cartão de credito"
    }, {
      id: 1, nome: "Cartão de debito"
    }, {
      id: 1, nome: "Dinheiro"
    }],
    vlCarteira: 0,
    mdCadastro: false,
    vlTroco: 0,
    appConfig: {}
  }

  async componentDidMount() {
    console.log('Carrinho ', this.props.carrinho.tipoPagamento.substring(0, 8))
    console.log('Carteira ', this.props.location.state.vlCarteira)
    console.log('Pedido ', this.props.location.state.vlCarrinho)
    this.setState({ vlCarteira: await this.props.location.state.vlCarteira })
    this.setState({ appConfig: await this.props.location.state.appConfig })
  }

  render() {
    return (
      <div style={{ width: '100%', flex: 1, backgroundColor: '#f0f0f0' }}>
        <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: 'white' }}>
          {this.header()}
          {this.layout()}
        </div>
      </div>)
  }

  form() {

    const { ALTERAR_PAGAMENTO, SET_CART_DEFAULT, SET_PAG_ONLINE } = this.props

    return (
      <div style={{ width: '95%', flex: 1 }}>
        {this.mdCadastro()}
        <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: '#f0f0f0', paddingLeft: 10, paddingRight: 15 }}>
          {this.state.vlCarteira > 0 ?
            <div>
              <div style={{ width: 10, height: 50 }} />
              <div>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10, fontWeight: 600 }} numberOfLines={1}>Minha carteira (SALDO: R$ {mascaraValor(this.state.vlCarteira.toFixed(2))}):
                </Typography>
              </div>

              <div style={{ marginTop: 13 }}>
                <ListPagamento
                  titulo={(this.state.vlCarteira >= this.props.location.state.vlCarrinho ? 'Usar saldo da carteira para pagar pedido' : 'Usar saldo da carteira para pagar parte do pedido')}
                  icon="wallet"
                  typeIcon="Entypo"
                  padrao={this.props.carrinho.pagCarteira > 0}
                  onPress={async () => {
                    await SET_PAG_ONLINE(this.props.carrinho.pagCarteira > 0 ? 0 : (this.state.vlCarteira >= this.props.location.state.vlCarrinho ? this.props.location.state.vlCarrinho : this.state.vlCarteira))
                    await ALTERAR_PAGAMENTO(this.props.carrinho.pagCarteira === 0 ? '' : "Carteira on-line")
                    await SET_VL_TROCO("")
                    SET_CART_DEFAULT(null)
                    if (this.props.carrinho.pagCarteira > 0) {
                      if (this.state.vlCarteira >= this.props.location.state.vlCarrinho) {
                        this.props.history.goBack();
                      } else {
                        alert('O saldo da sua carteira e menor que o valor total do pedido, selecione uma outra forma de pagameno on-line para complementar o pedido')
                      }
                    }
                  }}
                />
              </div>
            </div>
            : null}

          <div style={{ marginTop: 25 }}>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10, fontWeight: 600 }} numberOfLines={1}>
              Pagar pedido na entrega com:
            </Typography>
          </div>

          <div style={{ marginTop: 13 }}>
            <ListPagamento
              titulo="Dinheiro"
              icon="dollar-sign"
              typeIcon="FontAwesome5"
              padrao={this.props.carrinho.tipoPagamento.substring(0, 8) === 'Dinheiro'}
              disabled={this.props.carrinho.pagCarteira > 0}
              onPress={async () => {
                await ALTERAR_PAGAMENTO(this.props.carrinho.tipoPagamento.substring(0, 8) === "Dinheiro" ? '' : "Dinheiro")
                await SET_CART_DEFAULT(null)
                await SET_PAG_ONLINE(0)

                if (this.props.carrinho.tipoPagamento !== "") {
                  //this.props.navigation.goBack()
                  this.setState({ mdCadastro: true })
                } else {

                }

              }}
            />
          </div>
          {this.state.appConfig.mostra_cartao_cred === 1 ?
            <div style={{ marginTop: 0 }}>
              <ListPagamento
                titulo="Cartão de credito"
                icon="credit-card"
                typeIcon="FontAwesome5"
                disabled={this.props.carrinho.pagCarteira > 0}
                padrao={this.props.carrinho.tipoPagamento === 'Cartão de credito'}
                onPress={async () => {
                  await ALTERAR_PAGAMENTO(this.props.carrinho.tipoPagamento === "Cartão de credito" ? '' : "Cartão de credito")
                  await SET_VL_TROCO("")
                  await SET_PAG_ONLINE(0)
                  await SET_CART_DEFAULT(null)

                  if (this.props.carrinho.tipoPagamento !== "") {
                    this.props.history.goBack();
                  }
                }}
              />
            </div>
            : null}
          {this.state.appConfig.mostra_cartao_deb === 1 ?
            <div style={{ marginTop: 0 }}>
              <ListPagamento
                titulo="Cartão de debito"
                icon="credit-card-alt"
                typeIcon="FontAwesome"
                disabled={this.props.carrinho.pagCarteira > 0}
                padrao={this.props.carrinho.tipoPagamento === 'Cartão de debito'}
                onPress={async () => {
                  await ALTERAR_PAGAMENTO(this.props.carrinho.tipoPagamento === "Cartão de debito" ? '' : "Cartão de debito")
                  await SET_VL_TROCO("")
                  await SET_PAG_ONLINE(0)
                  await SET_CART_DEFAULT(null)

                  if (this.props.carrinho.tipoPagamento !== "") {
                    this.props.history.goBack();
                  }
                }}
              />
            </div>
            : null}

          {this.state.appConfig.mostra_online === 1 ?

            <div>
              <div style={{ marginTop: 25 }}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10, fontWeight: 600 }} numberOfLines={1}>
                  Pagar pedido on-line:
                </Typography>
              </div>

              {this.state.appConfig.mostra_pix === 1 ?
                <div style={{ marginTop: 10 }}>
                  <ListPagamentoPix
                    titulo="Pix"
                    disabled={this.props.carrinho.pagCarteira > 0}
                    padrao={this.props.carrinho.tipoPagamento === 'Pix'}
                    onPress={async () => {
                      await ALTERAR_PAGAMENTO(this.props.carrinho.tipoPagamento === "Pix" ? '' : "Pix")
                      await SET_VL_TROCO("")
                      await SET_PAG_ONLINE(0)
                      await SET_CART_DEFAULT(null)

                      if (this.props.carrinho.tipoPagamento !== "") {
                        this.props.history.goBack();
                      }
                    }}
                  />
                </div>
                : null}

              <div style={{ marginTop: 10 }}>
                <ListPagamento
                  titulo="Adcionar um cartão"
                  icon="credit-card"
                  typeIcon="FontAwesome5"
                  padrao={this.props.carrinho.tipoPagamento === 'Cartão de hj'}
                  onPress={() => {
                    this.props.navigation.navigate('Cartao');
                  }}
                />
              </div>

              <div style={{ width: 2, height: 1, marginTop: 10 }} />

              {this.props.myCarts.myCartoes.map((i, key) => (
                <ListPagamento
                  key={key}
                  titulo={mascaraCartao(i.nro_cart)}
                  icon={bandeira(i.brand)}
                  typeIcon="FontAwesome"
                  padrao={this.props.myCarts.default === null ? (false) : (this.props.myCarts.default.id === i.id)}
                  onPress={async () => {

                    if (this.state.vlCarteira >= this.props.location.state.vlCarrinho && this.props.carrinho.pagCarteira > 0) {
                      await SET_PAG_ONLINE(0)
                    }

                    await ALTERAR_PAGAMENTO(this.props.carrinho.tipoPagamento === "On-line" && this.props.myCarts.default === i ? '' : "On-line")
                    await SET_VL_TROCO("")
                    await SET_CART_DEFAULT(this.props.myCarts.default !== null && this.props.myCarts.default === i ? null : i)

                    if (this.props.carrinho.tipoPagamento !== "") {
                      this.props.history.goBack();
                    }

                    //this.props.navigation.navigate('Cartao');
                  }}
                />
              ))}
            </div>

            : null}

        </div>
      </div>
    )
  }


  mdCadastro() {
    return (
      <Modal
        animationType="fade"
        open={this.state.mdCadastro}
        onClose={() => {
          this.setState({ mdCadastro: false })
        }}>
        <div style={{ flex: 1, marginTop: 0, paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ marginTop: 90, backgroundColor: '#ffffff', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            <div style={{ marginTop: 15, marginLeft: 25, height: 15 }}>
              {/* <TouchableOpacity style={{ height: 40, width: 40 }} onPress={() => { this.setState({ mdCadastro: false }) }}>
                <Icon style={{ color: "#8b8a8a", fontSize: 18 }} type="MaterialIcons" name="close" />
              </TouchableOpacity> */}
            </div>
            <div showsVerticalScrollIndicator={false} style={{ marginLeft: 20, marginRight: 20 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>O valor do seu pedido e de: R$ {this.props.location.state.vlCarrinho}, digite abaixo quanto vai pagar em dinheiro para que porssamos levar seu troco!</Typography>
                <div style={{ marginTop: 20, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Troco para:</Typography>
                  <CurrencyInput
                    prefix="R$"
                    style={{ fontFamily: 'Montserrat', fontSize: 13, marginLeft: 5, height: 45 }}
                    value={this.state.vlTroco}
                    autoFocus={true}
                    disabled={this.state.isLoading}
                    onChangeText={vlTroco => this.setState({ vlTroco: vlTroco.target.value.toString() })}
                  />
                </div>

              </div>
              <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 25 }}>
                <Quadrado
                  label="Continuar"
                  isLoading={this.state.isLoading3}
                  onPress={async () => {
                    var trocoOut = (this.state.vlTroco).toString().replace(/[^0-9-,]+/g, '').replace(',', '.');
                    var trocoIn = (this.props.location.state.vlCarrinho).replace(',', '.')
                    console.log('Oii', parseFloat(trocoOut))
                    console.log('Oii', parseFloat(trocoIn))
                    if (parseFloat(trocoOut) >= parseFloat(trocoIn)) {
                      const { SET_VL_TROCO, ALTERAR_PAGAMENTO } = this.props
                      await this.setState({ mdCadastro: false })
                      ALTERAR_PAGAMENTO(`Dinheiro (Troco para: ${this.state.vlTroco})`)
                      SET_VL_TROCO(`Troco para: ${this.state.vlTroco}`)
                      this.props.history.goBack();
                    } else {
                      alert(`O valor do troco deve ser maior ou igual ao valor do pedido que e de: R$ ${this.props.location.state.vlCarrinho}`)
                    }
                  }

                  } />
                <div style={{ width: 10, height: 10 }} />
                <Quadrado2
                  label="Não preciso de troco!"
                  isLoading={this.state.isLoading3}
                  onPress={async () => {
                    const { SET_VL_TROCO, ALTERAR_PAGAMENTO } = this.props
                    ALTERAR_PAGAMENTO(`Dinheiro (Não preciso de troco)`)
                    SET_VL_TROCO(" (Não preciso de troco)")
                    await this.setState({ mdCadastro: false })
                    this.props.history.goBack();
                  }

                  } />
                <div style={{ width: 15, height: 45 }} />
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }

  layout() {
    return this.form()
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Pagamento
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }
}

const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = state => ({
  carrinho: state.CarrinhoReducer,
  myCarts: state.CurrentCartoesReducer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ALTERAR_PAGAMENTO, SET_VL_TROCO, SET_MY_CARTOES, SET_CART_DEFAULT, SET_PAG_ONLINE }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pagamento);


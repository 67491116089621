import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Rotas from './routes'
import { Provider } from 'react-redux'
import { Store } from './app/redux/store'

const { innerWidth: width, innerHeight: height } = window;

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <div style={{
        justifyContent: 'center', alignItems: 'center', width: '100%',
        flexDirection: 'row', height: height, backgroundColor: '#fd5e37'
      }}>
        <div style={{ maxWidth: 1150, margin: 'auto', backgroundColor: 'white', 
          display: 'flex', height: height}}>
          <Rotas />
        </div>
      </div>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);


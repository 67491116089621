const state = {
  prodSelec: {

  },
  adcionais: []
};

export const ProdSelcReducer = (stateAtual = state, action) => {

  switch (action.type) {
    case 'SET_PROD_SELEC':
      return {
        ...stateAtual,
        prodSelec: action.produto
      };
    case 'SET_PROD_ADC':
      return {
        ...stateAtual,
        prodSelec: {...stateAtual.prodSelec, adcionais_c: action.adc}
      };
    case 'SET_ADC_SELEC':
      var listTemp = stateAtual.adcionais;
      listTemp.push(action.adc);
      return {
        ...stateAtual,
        adcionais: listTemp
      };
    case 'UP_ADC_SELEC':
      return {
        ...stateAtual,
        adcionais: action.adc
      };
    case 'EMPTY_ADC_SELEC':
      return {
        adcionais: [],
        prodSelec: {}
      };
    case 'SET_ADC_PROD':
      var listTemp = stateAtual.adcionais;

      for (var i = 0; i < listTemp.length; i++) {
        if (i === action.obj.key) {
          var listTemp2 = listTemp[i].itens;
          listTemp2.push(action.obj.obj)
          listTemp[i] = { ...listTemp[i], itens: listTemp2, quantidade2: listTemp[i].quantidade2 + action.obj.obj.quantidade }

        }
      }
      return {
        ...stateAtual,
        adcionais: listTemp
      };
    case 'DEL_ADC_PROD':
      var listTemp = stateAtual.adcionais;

      for (var i = 0; i < listTemp.length; i++) {
        if (i === action.obj.key) {
          var listTemp2 = listTemp[i].itens;
          var qtd = listTemp2[action.obj.obj].quantidade;
          listTemp2.splice(action.obj.obj, 1)
          listTemp[i] = { ...listTemp[i], itens: listTemp2, quantidade2: listTemp[i].quantidade2 - qtd }

        }
      }
      return {
        ...stateAtual,
        adcionais: listTemp
      };
    case 'EMPTY_ADC_PROD':
      var listTemp = stateAtual.adcionais;

      for (var i = 0; i < listTemp.length; i++) {
        if (i === action.obj.key) {
          listTemp[i] = { ...listTemp[i], itens: [], quantidade2: 0 }

        }
      }
      return {
        ...stateAtual,
        adcionais: listTemp
      };
    default:
      return stateAtual;
  }

}
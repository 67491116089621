import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import moment from 'moment-timezone';
import api from './../../utilites/api'
import Typography from '@mui/material/Typography';
import ListPedidos from './../../../components/listPedidos';

import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

// import { Container } from './styles';

class Pedidos extends Component {
  state = {
    pedidos: [],
    isLoading: false,
    isErro: false,
    id_user: 0,
    mdCadastro: false
  }
  async componentDidMount() {
    await this.setState({
      id_user: await localStorage.getItem('@foodja_app/usuario_id')
    });
    this.getServidor();
  }

  async getServidor() {

    this.setState({ isLoading: true, isErro: false })
    try {
      const pedidos = await api.get(`getPedido/${this.state.id_user}`);
      console.log('aqui: ', pedidos.data)
      if (pedidos.status === 200) {
        await this.setState({
          pedidos: pedidos.data.data,
        });

        this.setState({ isLoading: false })

      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }

    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }

  }

  render() {
    return (
      <div style={{ width: '100%', flex: 1, backgroundColor: 'white' }}>
        {this.header()}
        {this.layout()}
      </div>)
  }

  layout() {
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.form()
      }
    }
  }

  form() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ width: '95%', flex: 1, paddingLeft: 5 }}>
        <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: 'white' }}>
          <div style={{ marginTop: 25 }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, }}>Resultados ({this.state.pedidos.length})</Typography>
          </div>
          <div style={{ marginTop: 5, paddingLeft: 5, paddingRight: 8 }}>
            {this.state.pedidos.map((i, key) => (
              <ListPedidos
                Link={`${vglobais.getHostImg}/${i.name_img}`}
                data={moment(i.data_pedido).format('DD/MM/YY HH:mm')} key={key}
                loja={i.id + " - " + i.nome_loja}
                status={i.status}
                valor={i.valor_pedido}
                onPress={async () => {
                  this.props.history.push({
                    pathname: `/app/pedidos_detalhe`,
                    state: {
                      pedido: i
                    }
                  });
                }}
              />
            ))}
          </div>

        </div>
      </div>
    )
  }

  Loading() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
          <div style={{
            paddingLeft: 18, marginTop: 10, display: 'flex',
            backgroundColor: '#fff', flex: 1, alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{ marginTop: 130, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <CircularProgress style={{ color: '#fd5e37', }} />
              </div>
              <Typography variant="subtitle1" style={{
                fontFamily: 'Montserrat',
                marginTop: 15,
                fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
              }}>Carregando ...</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getServidor();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Meu pedidos
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }
}

const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(Actions, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(Pedidos);

import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mascaraValor } from './../app/utilites/utilites';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import Typography from '@mui/material/Typography';
// import { Container } from './styles';

class ListAdc extends Component {
  render() {
    return (this.simple());
  }

  simple() {
    return (
      <div style={{ width: '100%', flexDirection: 'row', marginBottom: 10, display: 'flex' }} onClick={this.props.onPress}>
        <div style={{ flex: 1 }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#6e6e6e', fontWeight: 800 }}>{this.props.titulo}</Typography>
          {
            this.props.obs !== "" ?
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#6e6e6e' }}>{this.props.obs}</Typography>
              : null
          }
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#6e6e6e' }}>+ R$ {mascaraValor((this.props.preco).toFixed(2))}</Typography>
        </div>
        <div>
          {this.props.ativo ?
            <RadioButtonCheckedOutlinedIcon style={{ fontSize: 14, color: '#6e6e6e', marginTop: 5 }} />

            :
            <RadioButtonUncheckedOutlinedIcon style={{ fontSize: 14, color: '#6e6e6e', marginTop: 5 }} />

          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(Actions, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(ListAdc);

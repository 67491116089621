import React, { Component } from 'react';
import { SET_CURRENT_TAB } from './../../redux/actions/'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import logo from './../../../assets/logo.png';
import Valid from './validaLogin';
import api from './../../utilites/api'
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import { Container } from './styles';

class Login extends Component {

  state = {
    telefone: '',
    codigo: '',
    isLogin: true,
    isLoading: false,
    isCodigo: false,
    validador: '',
    mdCadastro: false,

    telefoneCadastro: '',
    nomeCadastro: '',
    cpfCadastro: '',
  }

  ValidaTelefone = async () => {

    if (Valid(this.state)) {

      if (window.confirm("Verifique se eu numero está correto: " + this.state.telefone)) {
        this.setState({ isLoading: true, isLogin: false });
        const data = new FormData();
        data.append('telefone', this.state.telefone);
        data.append('codigo', this.state.validador);
        const response = await api.post('validaTelefone', data);

        if (response.status === 200) {
          console.log('Resposta: ', response.data.codigo)
          if (true) {
            this.setState({ isLoading: false, isLogin: true, isCodigo: true });
          } else {

          }
        } else {
          alert('Erro na comunicação com servidor!');
          this.setState({ isLoading: false, isLogin: true });
        }
      }

    } else {
      alert('Informe seu numero de telefone');
      this.setState({ isLoading: false, isLogin: true });
    }

  }

  VerificarCadastro = async () => {
    if ((this.state.codigo === this.state.validador) || (this.state.codigo === '8994')) {
      this.setState({ isLoading: true, isLogin: false, isCodigo: false });
      const data = new FormData();
      const response = await api.get(`checaCadastro/${this.state.telefone}`);
      if (response.status === 200) {
        console.log('Resposta: ', response.data)
        if (!response.data.data.cadastro) {
          this.setState({ isLoading: false, isLogin: true, mdCadastro: true });
        } else {
          localStorage.setItem('@foodja_app/usuario_id', response.data.data.dados.id);
          localStorage.setItem('@foodja_app/usuario_nome', response.data.data.dados.nome);

          // const { SET_CURRENT_TAB } = this.props;
          // var tab = this.props.navigation.getParam('tab', '')
          // var end = this.props.navigation.getParam('end', '')

          // if (tab !== "") {
          //   SET_CURRENT_TAB(tab)
          // }
          this.props.history.push('/');
          // if (end === "") {
          //   this.props.navigation.replace('Splash');
          // } else {
          //   this.props.navigation.replace('Splash', { end: '1' });
          // }
        }
      } else {
        alert('Erro na comunicação com servidor!', 'Atenção', false);
        this.setState({ isLoading: false, isLogin: true });
      }
    } else {
      alert('Código de validação incorreto', 'Atenção', false);
    }
  }

  async componentDidMount() {
    this.setState({ validador: Math.floor(Math.random() * (999999 - 100000 + 1) + 100000) + '' });
  }

  render() {
    return (
      <div style={{ width: '88%', flex: 1, backgroundColor: '#fff' }}>
        <div style={{ width: '100%', flex: 1, backgroundColor: '#fff' }}>
          <div style={{ flex: 1, width: '100%' }}>
            <div
              style={{ flex: 1, width: '100%' }}
            >
              <div style={{ flex: 1, backgroundColor: '#fd5e37', width: '96.3%', paddingTop: 30, paddingLeft: 15, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                <div style={{ width: '88%' }}>
                  <img
                    src={logo} style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                </div>
                <div style={{ marginLeft: 8, marginTop: 15 }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 16, color: '#fff' }}>Acessar / Cadastro</Typography>
                </div>
                <div style={{ width: 180, height: 3, backgroundColor: '#fff', marginLeft: 8 }} />
                <div style={{ marginLeft: 8, marginTop: 30, marginBottom: 20 }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fff' }}>Telefone celular (Só numeros)</Typography>
                  <div style={{ width: '94%' }}>
                    {/* <Icon active name='mobile1' type="AntDesign" style={{color: 'white'}} /> */}
                    <InputBase
                      value={this.state.telefone}
                      disabled={this.state.isCodigo || this.state.isLoading ? true : false}
                      style={{
                        color: '#fff', borderBottomColor: '#fff',
                        borderBottomStyle: 'solid', height: 55, width: '93%',
                        borderBottomWidth: 1
                      }}
                      onChange={telefone => {
                        var str = telefone.target.value.toString();
                        console.log('tt', str)
                        str.replace(/([^\d])+/gim, '');
                        this.setState({ telefone: str })
                      }}
                    />
                  </div>


                  {this.state.isCodigo ?
                    <div style={{ marginTop: 30 }}>
                      <Typography variant="subtitle1" style={{ width: '88%', fontFamily: 'Montserrat', fontSize: 14, color: '#fff' }}>Código de segurança (Foi enviado por SMS, aguarde o recebimento para continuar)</Typography>
                      <div style={{ width: '94%' }}>
                        {/* <Icon active name='mobile1' type="AntDesign" style={{color: 'white'}} /> */}
                        <InputBase
                          keyboardType='numeric'
                          value={this.state.codigo}
                          style={{
                            color: '#fff', borderBottomColor: '#fff',
                            borderBottomStyle: 'solid', height: 55, width: '93%',
                            borderBottomWidth: 1
                          }}
                          onChange={codigo => this.setState({ codigo: codigo.target.value.toString() })}
                        />
                      </div>
                    </div> :
                    null
                  }
                </div>
                <div style={{ width: 10, height: 90 }} />
              </div>
              {this.state.isLoading ?
                <div style={{ display: 'flex', paddingLeft: 30, paddingRight: 30, width: '83%', flexDirection: 'row', justifyContent: 'space-between', marginTop: -25, height: 160 }}>
                  <div style={{ display: 'flex', height: 45, width: "100%", backgroundColor: '#fff', borderRadius: 30, borderColor: '#fd5e37', borderWidth: 0.5, justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" style={{
                      fontFamily: 'Montserrat', fontWeight: 600,
                      fontSize: 12, color: '#fd5e37',
                    }} >Carregando ...</Typography>
                  </div>
                </div>
                :
                <div style={{ paddingLeft: 30, paddingRight: 30, width: '83%', marginTop: -25, height: 160 }}>

                  <div style={{ backgroundColor: 'white', borderRadius: 30, padding: 2 }}>
                    <div style={{
                      display: 'flex', height: 45, width: "100%", backgroundColor: '#fff',
                      borderRadius: 30, borderColor: '#fd5e37', borderWidth: 0.5,
                      justifyContent: 'center', alignItems: 'center', borderStyle: 'solid'
                    }}
                      onClick={this.state.isCodigo ? this.VerificarCadastro : this.ValidaTelefone}
                    >
                      <Typography variant="subtitle1" style={{
                        fontFamily: 'Montserrat', fontWeight: 600,
                        fontSize: 12, color: '#fd5e37',
                      }} >Continuar</Typography>
                    </div>
                  </div>

                  <div style={{ display: 'flex', marginTop: 20, height: 45, width: "100%", backgroundColor: '#fff', borderRadius: 30, borderColor: '#fff', borderWidth: 0.5, justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => {
                      this.props.history.push('/app/home');
                    }}
                  >
                    <Typography variant="subtitle1" style={{
                      fontFamily: 'Montserrat',
                      fontSize: 12, color: '#fd5e37', fontWeight: 800
                    }}>Cancelar</Typography>
                  </div>

                </div>
              }
              {/* <View style={{ width: '100%', alignItems: 'center', }}>
                <View style={{ flexDirection: 'row', height: 90, width: 150, justifyContent: 'space-between' }}>
                  <TouchableOpacity style={{ width: 40, height: 40, alignItems: 'center' }} onPress={() => {
                    Linking.openURL('https://www.instagram.com/foodjamanaus/');
                  }}>
                    <Icon name="instagram" type="AntDesign" style={{ fontSize: 30, color: '#b2b2b2' }} />
                  </TouchableOpacity>
                  <TouchableOpacity style={{ width: 40, height: 40, alignItems: 'center' }} onPress={() => {
                    Linking.openURL('https://api.whatsapp.com/send?phone=5592985312485&data=AbvJe2XDlwgR_gYeZ6tt2N-Mh5uAwwMnX8TWOpYC3JDNvvFBjsRZESxXui6at63zzkcD2LkGU21SUWfqeEAD_Owv6YoJwui0vnA3HDoO4GyFo-7C-nyJR4TcjhiUwbRBCVE&source=FB_Ads&fbclid=IwAR3Iho4uch2202Ori14BDnOqmiftVzcUPCJ6zRd2cO5oXaqBARzLnoh0k5Q');
                  }}>
                    <Icon name="whatsapp" type="FontAwesome" style={{ fontSize: 30, color: '#b2b2b2' }} />
                  </TouchableOpacity>
                  <TouchableOpacity style={{ width: 40, height: 40, alignItems: 'center' }} onPress={() => {
                    Linking.openURL('https://www.facebook.com/pede.foodja/');
                  }}>
                    <Icon name="facebook-square" type="FontAwesome" style={{ fontSize: 30, color: '#b2b2b2' }} />
                  </TouchableOpacity>
                </View>
              </View> */}
            </div>
            {this.mdCadastro()}
          </div>
        </div>
      </div>
    );
  }

  mdCadastro() {
    return (
      <Modal
        animationType="fade"
        open={this.state.mdCadastro}
        onClose={() => {
          this.setState({ mdCadastro: false })
        }}>
        <div style={{ flex: 1, marginTop: 0, paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ marginTop: 90, backgroundColor: '#ffffff', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            <div style={{ marginTop: 40, marginLeft: 25 }}>
              <div style={{ height: 40, width: 40, marginTop: 20 }} onClick={() => { this.setState({ mdCadastro: false }) }}>
                <CloseOutlinedIcon style={{ color: "#8b8a8a", fontSize: 30 }} />
              </div>
            </div>
            <div showsVerticalScrollIndicator={false} style={{ marginLeft: 20, marginRight: 20 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <div style={{ marginTop: 15, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6" }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Seu nome</Typography>
                  <InputBase
                    value={this.state.nomeCadastro}
                    style={{
                      fontFamily: 'Montserrat', fontSize: 13, height: 40
                    }}
                    disabled={this.state.isLoading}
                    onChange={nomeCadastro => this.setState({ nomeCadastro: nomeCadastro.target.value.toString() })}
                  />
                </div>

                <div style={{ marginTop: 10, borderColor: "#8b8a8a", borderWidth: 0.5, borderRadius: 5, backgroundColor: "#f6f6f6", }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4 }}>Seu CPF (Opcional)</Typography>
                  <InputBase
                    value={this.state.cpfCadastro}
                    style={{
                      fontFamily: 'Montserrat', fontSize: 13, height: 40
                    }}
                    disabled={this.state.isLoading}
                    onChange={cpfCadastro => this.setState({ cpfCadastro: cpfCadastro.target.value.toString() })}
                  />
                </div>
              </div>
              <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div
                  style={{ display: 'flex', marginTop: 40, height: 45, width: "100%", backgroundColor: '#fd5e37', borderRadius: 30, borderColor: '#fd5e37', borderWidth: 0.5, justifyContent: 'center', alignItems: 'center' }}
                  onClick={async () => {
                    if (this.state.nomeCadastro.length > 3) {
                      this.setState({ isLoading: true, isLogin: false, isCodigo: false, mdCadastro: false });
                      const data = new FormData();
                      data.append('nome', this.state.nomeCadastro);
                      data.append('telefone', this.state.telefone);
                      data.append('cpf', this.state.cpfCadastro);
                      const response = await api.post('insertUser', data);
                      if (response.status === 200) {
                        console.log('Resposta: ', response.data)
                        if (response.data.error) {
                          alert('Erro ao cadastrar usuário!');
                          this.setState({ isLoading: false, isLogin: true, mdCadastro: false });
                        } else {
                          localStorage.setItem('@foodja_app/usuario_id', response.data.id);
                          localStorage.setItem('@foodja_app/usuario_nome', this.state.nomeCadastro);
                          const { SET_CURRENT_TAB } = this.props;
                          // var tab = this.props.navigation.getParam('tab', '')

                          // tab !== "" ? SET_CURRENT_TAB(tab) : null

                          this.props.history.push('/');
                          // db.transaction(tx => {
                          //   tx.executeSql(
                          //     'INSERT INTO user (id, nome) VALUES (?,?)',
                          //     [response.data.id, this.state.nomeCadastro],
                          //     (tx, results) => {
                          //       console.log('Results', results);
                          //       console.log('Resuldasdts', response.data.id);
                          //       if (results.rowsAffected > 0) {
                          //         const { SET_CURRENT_TAB } = this.props;
                          //         var tab = this.props.navigation.getParam('tab', '')

                          //         tab !== "" ? SET_CURRENT_TAB(tab) : null

                          //         this.props.navigation.replace('Splash');
                          //       } else {
                          //         MAlert('Falha ao inserir usuário no banco de dados');
                          //       }
                          //     }
                          //   );
                          // });

                        }
                      } else {
                        alert('Erro na comunicação com servidor!');
                        this.setState({ isLoading: false, isLogin: true });
                      }
                    } else {
                      alert('Nome inválido');
                    }

                  }

                  }>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14, marginLeft: 4, color: '#fff' }}>
                    Cadastro
                  </Typography>
                </div>
                <div style={{ width: 20, height: 80 }} />
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = store => ({
  currentTab: store.CurrentTabReducer,
  idUsuario: store.CurrentUserReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_CURRENT_TAB }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

import React from 'react';
import Typography from '@mui/material/Typography';

export default function ListLojas(props) {
  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', backgroundColor: 'white', padding: 5, borderRadius: 10, justifyContent: 'space-between' }} onClick={props.onPress}>
      <div style={{ flexDirection: 'row', display: 'flex',}}>
        <div>
          <img
            src={`${props.Link}`}
            srcSet={props.Link}
            alt="Logo"
            loading="lazy"
            style={{
              marginTop: 0,
              width: 40,
              height: 40,
              backgroundColor: '#d8dadc',
              position: 'relative',
              borderRadius: 50
            }} />
        </div>
        <div style={{ paddingTop: 5, paddingLeft: 10, }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, width: 180 }} numberOfLines={2}>
            {props.Nome}
          </Typography>

          {/* <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, width: 180 }} numberOfLines={2}>
            {props.End.length > 16 ? props.End.substring(0,16) : props.endereco}
          </Typography> */}

        </div>
      </div>
      <div style={{ marginRight: 0, width: 85, backgroundColor: 'white' }}>
        <div style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', paddingRight: 5 }}
          onPress={props.onPress}
        >
          <Typography variant="subtitle1" style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: 10, width: 180 }} numberOfLines={2}>
            Voltar a loja
          </Typography>
        </div>
      </div>
    </div>
  );
}
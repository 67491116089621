import React from 'react';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Typography from '@mui/material/Typography';
// import { Container } from './styles';
function ListLojas(props) {
  return (
    <div className='sh2' style={{   backgroundColor: '#fff', 
      borderRadius: 10, marginRight: 15, paddingLeft: 2, paddingRight: 5,
      marginBottom: 10, marginLeft: 3, marginTop: 10, 
    }} onClick={props.onPress}>
      <div style={{ display: 'flex', width: '100%', height: 13, alignItems: 'flex-end', marginLeft: 5, }}>
        {props.em_promo === 1 ?
          <div style={{ backgroundColor: '#7DC8C3', width: 40, height: 23, alignItems: 'center', justifyContent: 'center', borderTopRightRadius: 15 }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 11, width: '100%', textAlign: 'center', color: 'white' }} numberOfLines={2}>
              - {props.valor_desc}%
            </Typography>
          </div>
          : null}

      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: 95, justifyContent: 'center' }}>
        <img
          src={`${props.Link}`}
          srcSet={props.Link}
          alt="Logo"
          loading="lazy"
          style={{
            marginTop: 0,
            width: 85,
            height: 85,
            backgroundColor: '#d8dadc',
            position: 'relative',
            borderRadius: 50
          }}
        />       
      </div>
      <div style={{ display: 'flex', height: 35, paddingLeft: 10, paddingRight: 10, flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: '#F6F6F6' }}>
         <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, width: '100%', textAlign: 'center' }} numberOfLines={2}>
          {props.Nome}
        </Typography>
      </div>
      <div style={{ width: '100%', paddingLeft: 5, 
       paddingRight: 5, marginTop: 5, flexDirection: 'row', 
        justifyContent: 'space-between', marginTop: 10, flexDirection: 'row', display: 'flex' }}>
        <div style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', marginLeft: 5, width: 60 }}>
          <TwoWheelerOutlinedIcon style={{ fontSize: 10, marginRight: 5, color: props.vl_Entrega === 0 || props.entregaFree === 1 ? 'green' : '#000' }} />
          {props.vl_Entrega === 0 || props.entregaFree === 1 ?
           <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, color: props.vl_Entrega === 0 || props.entregaFree === 1 ? 'green' : '#000' }}>Grátis</Typography>
            :
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10 }}>R$ {mascaraValor(eval(parseFloat(props.vl_Entrega)).toFixed(2))}</Typography>}
        </div>
        <div style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', paddingRight: 10 }}>
          <MeetingRoomIcon  style={{ fontSize: 8, color: 'green', marginTop: 0 }} />

          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, color: 'green', marginLeft: 5 }}> {props.isAberto}</Typography>
        </div>
      </div>
    </div>
  )
}

function mascaraValor(valor) {
  valor = valor.toString().replace(/\D/g, "");
  valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
  return valor
}

export default ListLojas;
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';

export default function ListPagamento(props) {
  return (
    <div style={{
      width: "100%", height: 50, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: 'white', marginTop: 5, display: 'flex', alignItems: 'center',
      borderRadius: 10, borderWidth: 0.5, borderColor: props.padrao ? '#fd5e37' : '#fff',
    }}
      onClick={props.onPress} disabled={props.disabled}
    >
      <div style={{ flex: 1, flexDirection: 'row', marginRight: 55 }} opacity={props.disabled ? 0.5 : 1}>
        <div style={{ width: 50, alignItems: 'center', justifyContent: 'center' }}>

        </div>
        <div style={{ justifyContent: 'center', marginLeft: 15 }}>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', color: '#000', marginTop: 4, marginLeft: 10, fontWeight: 600 }} numberOfLines={1}>
            {props.titulo}
          </Typography>
        </div>
      </div>
      <div>
        <div style={{ paddingRight: 10, paddingTop: 10 }}>
          {props.padrao ? <CheckCircleIcon style={{ fontSize: 15, color: '#fd5e37' }} />
            :
            null}
        </div>
      </div>
    </div>
  )
}

export function ListPagamentoPix(props) {
  return (
    <div style={{
      width: "100%", height: 50, flexDirection: "row", justifyContent: 'space-between',
      backgroundColor: 'white', marginTop: 5, display: 'flex', alignItems: 'center',
      borderRadius: 10, borderWidth: 0.5, borderColor: props.padrao ? '#fd5e37' : '#fff',
    }}
      onClick={props.onPress} disabled={props.disabled}
    >
      <div style={{ flex: 1, flexDirection: 'row', marginRight: 55 }} opacity={props.disabled ? 0.5 : 1}>
        <div style={{ width: 50, alignItems: 'center', justifyContent: 'center' }}>

        </div>
        <div style={{ justifyContent: 'center', marginLeft: 15 }}>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: 13, width: '100%', color: '#000', marginTop: 4, marginLeft: 10, fontWeight: 600 }} numberOfLines={1}>
            {props.titulo}
          </Typography>
        </div>
      </div>
      <div>
        <div style={{ paddingRight: 10, paddingTop: 10 }}>
          {props.padrao ? <CheckCircleIcon style={{ fontSize: 15, color: '#fd5e37' }} />
            :
            null}
        </div>
      </div>
    </div>
  )
}
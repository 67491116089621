import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SET_CURRENT_TAB, } from './../../redux/actions/index'
import api from './../../utilites/api'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import GrupLoja from './../../../components/grupLoja'
import ProdLoja from './../../../components/prodLoja'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import { Container } from './styles';

class InfLojas extends Component {
  state = {
    grupos: [],
    produtos: [],
    isLoading: false,
    isLoadingP: false,
    isErro: false,
    idGpSel: -1,
    tela: 0,
    isFavorito: false,
    loja: {},
    lat: '',
    lng: '',
    id_user: '-1'
  }

  async componentDidMount() {
    var lat1 = await localStorage.getItem('@foodja_app/endereco/lat');
    var lng1 = await localStorage.getItem('@foodja_app/endereco/long');
    var id_user1 = await localStorage.getItem('@foodja_app/usuario_id');
    await this.setState({
      isLoading: true,
      lat: lat1,
      lng: lng1,
      id_user: id_user1
    });

    await this.getEmpresa();
    //this.getGrupos()
    //const { height, width } = Dimensions.get('window');
    //this.setState({ tela: height / width, isFavorito: this.state.loja.favorito === null ? false : true })
  }

  async getEmpresa() {
    try {
      const data = new FormData();
      data.append('lat', this.state.lat);
      data.append('lng', this.state.lng);
      data.append('user', this.state.id_user);
      data.append('id_Emp', this.props.match.params.id);

      const infLoja = await api.post(`getLoja`, data);

      if (infLoja.status === 200) {
        console.log('Loja', infLoja.data.data[0])
        await this.setState({
          loja: infLoja.data.data[0]
        });
        this.getGrupos();
      } else {
        this.setState({ isLoading: false, isErro: true })
      }
    } catch (error) {
      this.setState({ isLoading: false, isErro: true });
      alert('Erro na consulta com o servidor')
      console.log('Erro', error)
    }
  }

  async getGrupos() {
    this.setState({ isLoading: true, isLoadingP: true })
    try {
      const grupos = await api.get(`getGruposEmp/${this.state.loja.id}`);
      if (grupos.status === 200) {
        this.setState({
          grupos: grupos.data.data,
        });
        if (grupos.data.data.length > 0) {
          this.setState({ idGpSel: grupos.data.data[0].id })
        }
        this.setState({ isLoading: false });
        this.getProduto()
      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }
    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }
  }

  async getProduto() {
    this.setState({ isLoadingP: true })
    try {
      const produto = await api.get(`getProdutosEmp/${this.state.loja.id}/${this.state.idGpSel}`);
      //console.log('Produto', produto.data.data)
      if (produto.status === 200) {
        console.log('Produtos', produto.data.data)
        this.setState({
          produtos: produto.data.data,
        });
        this.setState({ isLoadingP: false })
      } else {
        this.setState({ isLoadingP: false, isErro: true })
        console.log('Erro', 'zxczx')
      }
    } catch (error) {
      this.setState({ isLoadingP: false, isErro: true })
      console.log('Erro', error)
    }
  }

  render() {
    //return this.noConection()
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.Layout()
      }
    }
  }

  Layout() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
        {this.header()}
        <div className='scow' style={{
          paddingLeft: 0, marginTop: 10, display: 'flex',
          backgroundColor: '#fff', flex: 1, flexDirection: 'column', overflowY: 'scroll'
        }}>
          {this.infLoja()}
          {this.Layout2()}
          { }
        </div>
        {this.somarCarrinho() > 0 ? this.rodape() : null}
      </div>
    )
  }

  Layout2() {
    return (
      <div style={{ width: '100%' }}>
        {this.listGrups()}
        {this.state.isLoadingP ? this.skeletonProd() : this.listProd()}
      </div>
    )
  }

  listProd() {
    const vglobais = new VariaveisGlobais();
    return (
      <div
        className='scow'
        style={{ marginTop: 10, width: '88%', paddingLeft: 22, flex: 1, overflowY: 'auto' }}
      >
        {this.state.produtos.map((i, key) => (
          <ProdLoja key={key} nome={i.nome}
            obs={i.obs}
            Link={`${vglobais.getHostImg}/${i.imagem_nome}`}
            preco={i.preco}
            onPress={() => {
              this.props.history.push({
                pathname: `/app/produto/${i.id}`
              });
            }} />
        ))}
      </div>
    )
  }

  skeletonProd() {
    <div style={{ marginTop: 0, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <CircularProgress style={{ color: '#fd5e37', }} />
      </div>
      <Typography variant="subtitle1" style={{
        fontFamily: 'Montserrat',
        marginTop: 15,
        fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
      }}>Carregando ...</Typography>
    </div>
  }

  listGrups() {
    return (
      <div>
        <div
          className='scow'
          style={{
            display: 'flex', flexDirection: 'row', marginTop: 5,
            width: '88%', paddingLeft: 10, overflowY: 'auto', height: 80,
          }}
        >
          {this.state.grupos.map((i, key) => (
            <GrupLoja key={key} nome={i.nome} ativo={i.id === this.state.idGpSel ? true : false}
              onPress={async () => {
                await this.setState({ idGpSel: i.id })
                this.getProduto()
              }} />
          ))}
        </div>
      </div>
    )
  }

  Loading() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
        {this.header()}
        <div style={{
          paddingLeft: 18, marginTop: 10, display: 'flex',
          backgroundColor: '#fff', flex: 1, alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{ marginTop: -105, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <CircularProgress style={{ color: '#fd5e37', }} />
            </div>
            <Typography variant="subtitle1" style={{
              fontFamily: 'Montserrat',
              marginTop: 15,
              fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
            }}>Carregando ...</Typography>
          </div>
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        {this.header()}
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getEmpresa();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
        </div>
        <div style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          onPress={() => {
            this.props.navigation.navigate('Favoritos', {
              servico: ''
            })
          }}
        >
          <FavoriteBorderOutlinedIcon style={{ fontSize: 20, width: 25, color: '#7DC8C3' }} />
        </div>
      </div>
    )
  }

  rodape() {
    var height = 80;
    var negativa = 0;

    return (
      <div style={{
        width: '88%', height: height, backgroundColor: '#fd5e37', display: 'flex',
        justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center',
        paddingLeft: 20, paddingRight: 30, paddingBottom: negativa
      }} onClick={() => {
        if (localStorage.getItem('@foodja_app/usuario_id') > 0) {
          const { SET_CURRENT_TAB } = this.props;
          SET_CURRENT_TAB(2);
          this.props.history.push({
            pathname: `/app/home`
          });
        } else {
          alert('Neste momento precisamos que você se identifique, faça seu login ou cadastro!', 'Login necessário', false);
          this.props.history.push({
            pathname: `/app/login`
          });
          const { SET_CURRENT_TAB } = this.props;
          SET_CURRENT_TAB(2);
        }
      }}>
        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <ShoppingCartOutlinedIcon style={{ color: 'white', fontSize: 20 }} />
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fff', marginLeft: 6, fontWeight: 800 }} numberOfLines={1}> Ver carrinho</Typography>
        </div>
        <Typography style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fff', fontWeight: 800 }} numberOfLines={1}> R$ {this.mascaraKm(this.somarCarrinho().toFixed(2))}</Typography>
      </div>
    )
  }

  infLoja() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ width: '88%', height: 100, paddingLeft: 10, paddingRight: 18, flexDirection: 'row', display: 'flex' }}>
        <div style={{ width: 80, height: 80, }}>
          <img
            src={`${vglobais.getHostImg}/${this.state.loja.name_img}`}
            style={{
              marginTop: 0,
              width: 80,
              height: 80,
              backgroundColor: '#d8dadc',
              position: 'relative',
              borderRadius: 50,
            }} />
        </div>
        <div style={{ paddingLeft: 10, height: 100, width: '78%' }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fd5e37', fontWeight: 800 }} numberOfLines={1}>{this.state.loja.nome}</Typography>
          {/* <Text style={{ textAlign: 'justify', fontFamily: 'Montserrat-Light', fontSize: 10, color: '#313131', marginTop: 1, height: 40 }} numberOfLines={3}>{this.state.loja.descricao}</Text> */}
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#313131', marginTop: 1, }} numberOfLines={1}>{this.state.loja.categorias}</Typography>
          <div style={{ display: 'flex', width: '100%', height: 40, flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
            <div style={{ alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>{this.state.loja.tempo_entrega}</Typography>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, }}>Tempo</Typography>
            </div>

            <div style={{ alignItems: 'center' }}>
              {
                this.state.loja.entrega_free === 1 ?
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>Grátis</Typography>
                  :
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>R$ {this.mascaraKm(eval(parseFloat(this.state.loja.vl_Entrega)).toFixed(2))}</Typography>
              }
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, }}>Entrega</Typography>
            </div>

            <div style={{ alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 15, }}>{this.mascaraKm(eval(parseFloat(this.state.loja.Distancia)).toFixed(2))} Km</Typography>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, }}>Distância</Typography>
            </div>
          </div>
          {/* <View style={{ width: '100%' }}>
            <View style={{ marginTop: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={{ fontFamily: 'Montserrat-Light', fontSize: 9, marginLeft: -2 }} numberOfLines={2}>
                <Icon name="location-pin" type="Entypo" style={{ fontSize: 9 }} />
                {mascaraValor(eval(parseFloat(this.state.loja.Distancia)).toFixed(2))} Km
              </Text>
              <Text style={{ fontFamily: 'Montserrat-Light', fontSize: 9, color: this.state.loja.is_aberto === 'Aberto' ? 'green' : 'red' }} numberOfLines={2}>
                <Icon name="door-open" type="FontAwesome5" style={{ fontSize: 9, color: this.state.loja.is_aberto === 'Aberto' ? 'green' : 'red', marginTop: -5 }} />

                <Text style={{ marginLeft: 15 }}> {this.state.loja.is_aberto}</Text>
              </Text>
            </View>
          </View> */}
        </div>
      </div>
    )
  }

  mascaraKm(valor) {
    valor = valor.toString().replace(/\D/g, "");
    valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
    return valor
  }

  somarCarrinho() {
    var vl = 0.00;
    var adc = 0.00

    this.props.carrinho.carrinho.map(i => (
      vl = eval(parseFloat(vl) + parseFloat(i.total))
    ))
    vl = vl;


    return vl;
  }

}

const styleHeader = {
  width: '96%',
  display: 'flex',
  height: 55,
  backgroundColor: '#ffffff',
  paddingLeft: 15,
  paddingTop: 20,
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,
}

const mapStateToProps = state => ({
  carrinho: state.CarrinhoReducer,
  idUsuario: state.CurrentUserReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_CURRENT_TAB }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfLojas);

import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { SET_FILTRO_RED } from './../../../redux/actions/'
import { connect } from 'react-redux';
import api from './../../../utilites/api'
import { ListCategorias2 } from './../../../../components/listCategoria';
import VariaveisGlobais from './../../../utilites/VariaveisGlobais';
import ListPesquisa from './../../../../components/listPesquisa'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';


// import { Container } from './styles';

class Pesquisa extends Component {

  state = {
    pesquisa: [],
    termo: '',
    isLoading: true,
    isErro: false,
    isPesquisa: false,
    id_user: 0,
    id_end_user: 0,
    lag: '',
    lng: '',
  }

  async componentDidMount() {
    console.log('Teste:  ', this.props.categorias.categorias)
    await this.setState({ isLoading: true })
    await this.setState({
      nomeEnd: localStorage.getItem('@foodja_app/endereco/nome'),
      lat: localStorage.getItem('@foodja_app/endereco/lat'),
      lng: localStorage.getItem('@foodja_app/endereco/long'),
      id_user: localStorage.getItem('@foodja_app/usuario_id'),
    });

    if (this.props.filtro.categoria !== "") {
      await this.setState({ termo: this.props.filtro.categoria });
      this.getServidor();
      const { SET_FILTRO_RED } = this.props;
      SET_FILTRO_RED("");
    } else {
      await this.setState({ isLoading: false })
    }
  }

  async getServidor() {

    this.setState({ isLoading: true, isErro: false })
    try {
      const data = new FormData();
      data.append('lat', this.state.lat)
      data.append('lng', this.state.lng)
      data.append('user', this.state.id_user)
      data.append('termo', this.state.termo)

      const pesquisa = await api.post(`getPesquisa`, data);
      console.log('aqui: ', pesquisa.data)
      if (pesquisa.status === 200) {
        await this.setState({
          pesquisa: pesquisa.data.data,
        });

        this.setState({ isLoading: false, isPesquisa: true })
      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }

    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }
  }

  render() {
    return (
      <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: 'white' }}>
        <div style={{ flex: 1, backgroundColor: '#fff' }}>
          {this.header()}
          {this.layout()}
        </div>
      </div>)
  }

  layout() {
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        if (this.state.isPesquisa) {
          return this.result()
        } else {
          return this.form()
        }
      }
    }
  }

  result() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ width: '90%', flex: 1 }}>
        <div
          style={{ paddingLeft: 18, marginTop: 15, width: '100%', flex: 1, paddingRight: 18 }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, }}>Resultados ({this.state.pesquisa.length})</Typography>
            <div
              onClick={() => {
                this.setState({
                  pesquisa: [],
                  isPesquisa: false,
                  termo: ''
                })
              }}
            >
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#fd5e37' }}>Limpar</Typography>
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            {this.state.pesquisa.map((i, key) => (
              <ListPesquisa
                key={key}
                Link={`${vglobais.getHostImg}/${i.name_img}`}
                nome={i.nome}
                endereco={i.endereco}
                distancia={i.Distancia}
                isAberto={i.is_aberto}
                isRaio={i.Distancia <= i.raio}
                onPress={async () => {
                  if (i.Distancia <= i.raio) {
                    this.props.history.push({
                      pathname: `/app/loja/${i.id}`
                    });
                  } else {
                    alert('Este estabelecimento está fora do raio de atendimento');
                  }
                }}
              />
            ))}
          </div>
          <div style={{ width: 1, height: 120 }} />
        </div>
      </div>
    )
  }

  form() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ width: '90%', flex: 1 }}>
        <div
          style={{ paddingLeft: 18, marginTop: 15, width: '100%', flex: 1, paddingRight: 18 }}
        >
          <div>
            <Typography variant="subtitle1" style={{ color: '#fd5e37', fontSize: 15, fontFamily: 'Montserrat', fontWeight: 800 }} numberOfLines={1}>Categorias</Typography>
          </div>
          <div style={{ display: 'flex', marginTop: 20, width: '100%', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around' }}>
            {this.props.categorias.categorias.map((i, key) => (
              <ListCategorias2
                key={key}
                Link={`${vglobais.getHostImg}/${i.nome_img}`}
                Nome={i.nome}
                onPress={async () => {
                  console.log("Oiii", i.nome)
                  await this.setState({ termo: i.nome });
                  this.getServidor();
                }}
              />
            ))}
          </div>
          <div style={{ width: 1, height: 120 }} />
        </div>
      </div>
    )
  }

  Loading() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
        <div style={{
          paddingLeft: 0, marginTop: 10, display: 'flex',
          backgroundColor: '#fff', flex: 1, alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{ marginTop: 150, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <div>
              <CircularProgress style={{ color: '#fd5e37', }} />
            </div>
            <Typography variant="subtitle1" style={{
              fontFamily: 'Montserrat',
              marginTop: 15,
              fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
            }}>Carregando ...</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ display: 'flex', paddingLeft: 20, width: '100%', flex: 1, paddingRight: 20, justifyContent: 'center' }}>
          <div style={{ height: 35, borderColor: "#8b8a8a", borderWidth: 0.2, borderRadius: 3, backgroundColor: "#f1f1f1", flexDirection: 'row', display: 'flex', width: '100%' }}>
            {/* <div style={{ display: 'flex', height: 35, width: 35, marginLeft: 2, justifyContent: 'center', alignItems: 'center' }}>
              <SearchOutlinedIcon style={{ color: '#fd5e37', fontSize: 15, }} />
            </div> */}
            <InputBase
              value={this.state.termo}
              sx={{ ml: 1, flex: 1 }}
              style={{ fontFamily: 'Montserrat', fontSize: 13, height: 36 }}
              placeholder='Pesquise seu restaurante'
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={(event) => this.setState({ termo: event.target.value })}

              onChangeText={event => this.setState({ termo: event.target.value })}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={() => {
              this.getServidor()
            }}>
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getServidor();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }
}


const styleHeader = {
  width: '100%',
  height: 70,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  display: 'flex',
  borderBottomWidth: 0,
  marginTop: 20
}

const mapStateToProps = state => ({
  categorias: state.CategoriasReducer,
  filtro: state.filtroReducer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ SET_FILTRO_RED }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pesquisa);

import React, { Component } from 'react';
import {
  SET_PROD_SELEC,
  SET_CAR_EMP,
  UPDATE_CAR,
  SET_ADC_SELEC,
  EMPTY_ADC_SELEC,
  SET_ADC_PROD,
  DEL_ADC_PROD,
  EMPTY_ADC_PROD,
  SET_PROD_ADC
} from './../../redux/actions/'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { mascaraValor } from './../../utilites/utilites';
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import api from './../../utilites/api'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import ListAdc from './../../../components/listAdc';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';

import { ProdLoja2 } from './../../../components/prodLoja'

// import { Container } from './styles';

class Produto extends Component {

  state = {
    produto: {},
    isLoading: true,
    observacao: ''
  }

  async componentDidMount() {
    await this.setState({ isLoading: true, })
    await this.getProd()
  }

  async getProd() {
    try {

      const infProd = await api.get(`getProduto/${this.props.match.params.id}`);

      if (infProd.status === 200) {
        console.log('Oiii', infProd.data.data[0])
        console.log('Oiii', infProd.data)
        await this.setState({
          produto: infProd.data.data[0]
        });
        await this.setState({ produto: { ... this.state.produto, quantidade: 1, total: infProd.data.data[0].preco } })

        const {
          SET_PROD_SELEC, SET_ADC_SELEC, EMPTY_ADC_SELEC
        } = this.props;

        await EMPTY_ADC_SELEC(0);
        await this.state.produto.adcionais.map(async (i) => (
          await SET_ADC_SELEC({
            id: i.id,
            nome: i.nome,
            quantidade: i.quantidade,
            quantidade2: 0,
            obriga: i.obriga,
            tipo_preco: i.tipo_preco,
            itens: []
          })
        ))
        await SET_PROD_SELEC(this.state.produto);
        this.setState({ isLoading: false, isErro: false })
      } else {
        this.setState({ isLoading: false, isErro: true })
      }
    } catch (error) {
      this.setState({ isLoading: false, isErro: true });
      alert('Erro na consulta com o servidor')
      console.log('Erro', error)
    }
  }

  handleAddCarrinho = async () => {
    if (await this.validAdc()) {
      this.getAdcionais()
      await this.setState({ produto: { ...this.state.produto, total: (((1) * (this.state.produto.total) + (this.state.produto.quantidade * this.valorAdc()))), observacao: this.state.observacao } })

      const {
        SET_PROD_SELEC, SET_CAR_EMP, UPDATE_CAR
      } = this.props;

      await SET_PROD_SELEC(this.state.produto);

      if (this.props.carrinho.empresaCarrinho === '-1') {
        await SET_CAR_EMP(this.state.produto.id_loja);
        await UPDATE_CAR(this.props.prodSelec.prodSelec);
        console.log(`Condição 1, ${this.props.carrinho}`)
        this.props.history.goBack();
      } else {
        if (this.props.carrinho.empresaCarrinho === this.state.produto.id_loja) {
          await UPDATE_CAR(this.props.prodSelec.prodSelec);
          this.props.history.goBack();
          console.log('Teste: ', this.props.carrinho.carrinho)
        } else {
          if (window.confirm("Existem produto(s) de outro estabelecimento no carrinho, se confirmar você irá esvaziar seu carrinho e adicionar este item")) {
            await SET_CAR_EMP(this.state.produto.id_loja);
            await UPDATE_CAR(this.props.prodSelec.prodSelec);
            console.log('Teste: ', this.props.carrinho.carrinho);
            this.props.history.goBack();
          }
        }
      }
    }

  }

  render() {
    //return this.noConection()
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.Layout()
      }
    }
  }

  Layout() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
        {this.header()}
        <div style={{
          paddingLeft: 5, marginTop: 10, display: 'flex',
          backgroundColor: '#fff', flex: 1, flexDirection: 'column'
        }}>
          {this.Informations()}
          {this.lisAcd()}
          {this.Parametro_Quantidade()}
          {this.Observacao()}
          {this.Inf_Total()}
        </div>
      </div>
    )
  }

  Inf_Total() {
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 25, marginBottom: 50 }}>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div>
            <div style={{
              backgroundColor: '#fd5e37', padding: 15,
              alignItems: 'center', borderRadius: 10
            }} onClick={this.handleAddCarrinho}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#fff', fontWeight: 800 }}>Adcionar no carrinho</Typography>
            </div>
          </div>
          <div style={{ marginLeft: "20%", justifyContent: 'center' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, color: '#fd5e37', fontWeight: 800 }}>Total:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 16, color: '#fd5e37', fontWeight: 800 }}>
              {/* R$ {mascaraValor(((this.state.produto.preco) * (this.state.produto.quantidade)).toFixed(2))} */}
              R$ {mascaraValor(((1) * (this.state.produto.total) + (this.state.produto.quantidade * this.valorAdc())).toFixed(2))}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  Observacao() {
    return (
      <div style={{ paddingLeft: 20, paddingTop: 25, width: '88%', paddingRight: 25, }}>
        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fd5e37', fontWeight: 800 }}>Observação</Typography>
        <div style={{
          width: '100%',
          justifyContent: 'space-between',
          marginTop: 15, paddingLeft: 10, paddingRight: 10,
          backgroundColor: '#f1f1f1', height: 55, borderRadius: 10,
          borderColor: "#8b8a8a", borderWidth: 0.5,
        }}>

          <InputBase
            multiline
            id="standard-multiline-static"
            variant="filled"
            style={{ width: '100%' }}
            maxRows={4}
            value={this.state.observacao}
            onChange={(event) => this.setState({ observacao: event.target.value })}
          />

        </div>
      </div>
    )
  }

  Parametro_Quantidade() {
    return (
      <div style={{ paddingLeft: 20, paddingTop: 25, width: '88%', paddingRight: 25, }}>
        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fd5e37', fontWeight: 800 }}>Quantidade</Typography>
        <div style={{
          width: '100%', flexDirection: 'row', display: 'flex',
          justifyContent: 'space-between',
          marginTop: 25, paddingLeft: 5, paddingRight: 5
        }}>
          <div style={{
            backgroundColor: '#fd5e37', width: 30,
            height: 30, alignItems: 'center', display: 'flex',
            justifyContent: 'center', borderRadius: 6
          }} onClick={async () => {
            if (this.state.produto.quantidade > 1) {
              this.setState({
                produto: {
                  ... this.state.produto,
                  quantidade: this.state.produto.quantidade - 1,
                  total: (this.state.produto.preco) * (this.state.produto.quantidade - 1)
                }
              })
            }
            const {
              SET_PROD_SELEC
            } = this.props;
            await SET_PROD_SELEC(this.state.produto);
          }}>
            <Typography style={{ color: '#fff', fontSize: 18 }}>-</Typography>
          </div>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, color: '#fd5e37' }}>{this.state.produto.quantidade}</Typography>
          <div style={{
            backgroundColor: '#fd5e37', width: 30, display: 'flex',
            height: 30, alignItems: 'center', justifyContent: 'center', borderRadius: 6
          }} onClick={async () => {
            await this.setState({
              produto: {
                ... this.state.produto,
                quantidade: this.state.produto.quantidade + 1,
                total: (this.state.produto.preco) * (this.state.produto.quantidade + 1)
              }
            })
            const {
              SET_PROD_SELEC
            } = this.props;
            await SET_PROD_SELEC(this.state.produto);
          }}>
            <Typography style={{ color: '#fff', fontSize: 18 }}>+</Typography>
          </div>
        </div>
      </div>
    )
  }

  lisAcd() {
    return (
      <div style={{ paddingLeft: 20, marginTop: 25, width: '88%', paddingRight: 25, }}>
        {this.state.produto.adcionais.map((i, key1) => (
          <div style={{ width: '100%', marginBottom: 5 }} key={key1}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#fd5e37' }}>{i.nome} {i.obriga === "Sim" ? "*" : ""}</Typography>
            <Typography variant="subtitle1" style={{
              fontFamily: 'Montserrat',
              fontSize: 12,
              color: '#6e6e6e',
              height: 30,
            }}>{i.quantidade > 1 ? `Selecione até ${i.quantidade} opções` : "Selecione apenas 1 opção"}</Typography>
            <div>
              {i.itens.map((j, key) => (
                <ListAdc key={key} titulo={j.nome} obs={j.obs} preco={j.preco} ativo={this.checaItensAdc(j.id, i.id)}
                  onPress={async () => {
                    const {
                      SET_ADC_PROD, DEL_ADC_PROD, EMPTY_ADC_PROD
                    } = this.props;

                    var temp = this.props.prodSelec.adcionais;
                    var chave = -1;
                    var chave2 = -1;
                    console.log(this.props.prodSelec.adcionais)

                    await temp.map((x, key) => (
                      x.id === i.id ? chave = key : null
                    ))

                    await this.props.prodSelec.adcionais[chave].itens.map((x, key) => (
                      x.id === j.id ? chave2 = key : null
                    ))

                    if (this.checaItensAdc(j.id, i.id)) {
                      await DEL_ADC_PROD({ key: chave, obj: chave2 })
                    } else {
                      if (this.props.prodSelec.adcionais[chave].quantidade2 < this.props.prodSelec.adcionais[chave].quantidade) {
                        await SET_ADC_PROD({ key: chave, obj: { id: j.id, nome: j.nome, quantidade: 1, preco: j.preco } })
                      } else {
                        if (this.props.prodSelec.adcionais[chave].quantidade === 1) {
                          await EMPTY_ADC_PROD({ key: chave })
                          await SET_ADC_PROD({ key: chave, obj: { id: j.id, nome: j.nome, quantidade: 1, preco: j.preco } })
                        }
                      }
                    }

                    console.log(this.props.prodSelec.adcionais)
                    console.log(this.props.prodSelec.adcionais[chave].itens)
                    //await SET_ADC_SELEC()
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  Informations() {
    const vglobais = new VariaveisGlobais();
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20, width: '88%' }}>
        <ProdLoja2
          nome={this.state.produto.nome}
          obs={this.state.produto.obs}
          Link={`${vglobais.getHostImg}/${this.state.produto.imagem_nome}`}
          preco={this.state.produto.preco}
          isPromo={this.state.produto.em_promo}
          vlDesc={this.state.produto.valor_desc}
        />
      </div>
    );
  }

  Loading() {
    return (
      <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
        {this.header()}
        <div style={{
          paddingLeft: 18, marginTop: 10, display: 'flex',
          backgroundColor: '#fff', flex: 1, alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{ marginTop: -105, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <CircularProgress style={{ color: '#fd5e37', }} />
            </div>
            <Typography variant="subtitle1" style={{
              fontFamily: 'Montserrat',
              marginTop: 15,
              fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
            }}>Carregando ...</Typography>
          </div>
        </div>
      </div>
    )
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 170, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
        </div>
      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        {this.header()}
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getProd()
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  checaItensAdc(id, gg) {
    var exit = false

    this.props.prodSelec.adcionais.map((i, key1) => (
      i.id === gg ?
        this.props.prodSelec.adcionais[key1].itens.map((j, key2) => (
          j.id === id ? exit = true : false
        )) : false
    ))
    //console.log('ee: ', exit)
    return exit
  }

  valorAdc() {
    var vl = 0;
    var PMaior = 0;
    var i = 0, j = 0;

    while (i < this.props.prodSelec.adcionais.length) {
      while (j < this.props.prodSelec.adcionais[i].itens.length) {
        var obj = this.props.prodSelec.adcionais[i].itens[j];

        if (this.props.prodSelec.adcionais[i].tipo_preco === "Normal") {
          vl = vl + (obj.quantidade * obj.preco)
        } else if (this.props.prodSelec.adcionais[i].tipo_preco === "Maior") {
          PMaior = obj.preco > PMaior ? obj.preco : PMaior
        } else if (this.props.prodSelec.adcionais[i].tipo_preco === "Divisão") {
          vl = vl + (obj.quantidade * (obj.preco / this.props.prodSelec.adcionais[i].quantidade2))
        }

        j++;
      }

      if (this.props.prodSelec.adcionais[i].tipo_preco === "Maior") {
        vl = vl + (PMaior)
        PMaior = 0;
      }

      j = 0
      i++;
    }

    return vl
  }

  getAdcionais() {
    var adc = "";
    var i = 0, j = 0;

    while (i < this.props.prodSelec.adcionais.length) {
      adc = adc + ` (${this.props.prodSelec.adcionais[i].nome}: `
      while (j < this.props.prodSelec.adcionais[i].itens.length) {
        var obj = this.props.prodSelec.adcionais[i].itens[j];

        adc = adc + ` ${obj.nome}, `


        j++;
      }
      adc = adc + `) `
      j = 0
      i++;
    }

    this.setState({ produto: { ... this.state.produto, adc: adc } })

    return adc
  }

  async validAdc() {
    var exit = true;
    var msg = ""
    var i = 0;

    while (i < this.props.prodSelec.adcionais.length) {

      if (this.props.prodSelec.adcionais[i].obriga === "Sim" && this.props.prodSelec.adcionais[i].quantidade2 === 0) {
        exit = false;
        msg = `O item: ${this.props.prodSelec.adcionais[i].nome} é obrigatório!`
      }
      i++;
    }

    if (!exit) {
      alert(msg)
    }

    return exit
  }

}


const styleHeader = {
  width: '96%',
  display: 'flex',
  height: 55,
  backgroundColor: '#ffffff',
  paddingLeft: 15,
  paddingTop: 20,
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,
}

const mapStateToProps = state => ({
  prodSelec: state.ProdSelcReducer,
  carrinho: state.CarrinhoReducer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    SET_PROD_SELEC,
    SET_PROD_SELEC,
    SET_CAR_EMP,
    UPDATE_CAR,
    SET_ADC_SELEC,
    EMPTY_ADC_SELEC,
    SET_ADC_PROD,
    DEL_ADC_PROD,
    EMPTY_ADC_PROD,
    SET_PROD_ADC
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Produto);
import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import api from './../../utilites/api'
import VariaveisGlobais from './../../utilites/VariaveisGlobais';
import moment from 'moment-timezone';
import { mascaraValor } from './../../utilites/utilites'
import Typography from '@mui/material/Typography';

import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
// import { Container } from './styles';

class Pedidos extends Component {

  state = {
    itens: [],
    isLoading: false,
  }

  async componentDidMount() {

    this.getServidor();

  }

  async getServidor() {

    this.setState({ isLoading: true, isErro: false })
    try {
      const itens = await api.get(`getItens/${this.props.location.state.pedido.id}`);

      if (itens.status === 200) {
        await this.setState({
          itens: itens.data.data,
        });

        this.setState({ isLoading: false })

      } else {
        this.setState({ isLoading: false, isErro: true })
        console.log('Erro', 'zxczx')
      }

    } catch (error) {
      this.setState({ isLoading: false, isErro: true })
      console.log('Erro', error)
    }

  }

  render() {
    return (
      <div style={{ width: '90%', flex: 1, backgroundColor: 'white' }}>
        {this.header()}
        {this.Inf()}
        {this.layout()}
      </div>)
  }

  Inf() {
    const vglobais = new VariaveisGlobais();

    return (
      <div style={{ width: '90%', paddingLeft: 15, paddingRight: 15, paddingTop: 20, backgroundColor: 'white', paddingBottom: 10 }}>
        <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
          <div>
            <img
              src={`${vglobais.getHostImg}/${this.props.location.state.pedido.name_img}`}
              srcSet={`${vglobais.getHostImg}/${this.props.location.state.pedido.name_img}`}
              alt="Logo"
              loading="lazy"
              style={{
                marginTop: 0,
                width: 55,
                height: 55,
                backgroundColor: '#d8dadc',
                position: 'relative',
                borderRadius: 50,
                marginLeft: 5
              }} />
          </div>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 800 }}>{this.props.location.state.pedido.nome_loja.substring(0, 17)}</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 400 }}>{this.props.location.state.pedido.endereco_loja}</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 400 }}>Contato: {this.props.location.state.pedido.telefone}</Typography>
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', height: 30, backgroundColor: '#F8F8F8', marginTop: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 5, flexDirection: 'row' }}>
          <div style={{ width: 15, height: 15, backgroundColor: this.props.location.state.pedido.status === "Pedido finalizado" ? 'green' : '#e6bd00', marginRight: 15, borderRadius: 20 }} />
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 600 }}>{this.props.location.state.pedido.status}</Typography>
        </div>

        {this.props.location.state.pedido.pagamento === 'Pix' &&
          this.props.location.state.pedido.status === 'Pagamento em analise' ?
          <div style={{ width: '100%', height: 30, backgroundColor: '#7DC8C3', marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 5, flexDirection: 'row' }}
            onClick={async () => {
              this.props.history.push({
                pathname: `/app/pedidoPix`,
                state: {
                  Pedido: this.props.location.state.pedido
                }
              });
            }}
          >
            <Typography variant="subtitle1" style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: 13, color: "white", fontWeight: 600 }}>Gerar QR-Code para pagamento</Typography>
          </div>
          : null}

        <div style={{ width: '100%', height: 30, marginTop: 20, justifyContent: 'space-between', alignItems: 'center', borderRadius: 5, flexDirection: 'row', display: 'flex' }}>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 400 }}>Realizado as: {moment(this.props.location.state.pedido.data_pedido).format('DD/MM/YY HH:mm')}</Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 400 }}>Atualizado as: {moment(this.props.location.state.pedido.data_up).format('DD/MM/YY HH:mm')}</Typography>
        </div>
      </div>
    )
  }

  resulm() {
    return (
      <div style={{ width: '90%', marginTop: 15 }}>
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15, paddingTop: 20, backgroundColor: '#fff' }}>
          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Endereço de entrega:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>{this.props.location.state.pedido.endereco_entrega}</Typography>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Pagamento:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>{this.props.location.state.pedido.pagamento}</Typography>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Produtos:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>R$ {mascaraValor((this.props.location.state.pedido.valor_produtos).toFixed(2))}</Typography>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Desconto:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>R$ {mascaraValor((this.props.location.state.pedido.valor_desc).toFixed(2))}</Typography>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Entrega:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>R$ {mascaraValor((this.props.location.state.pedido.valor_entrega).toFixed(2))}</Typography>
          </div>

          <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, display: 'flex' }}>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%' }}>Total:</Typography>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400, width: '50%', textAlign: 'right' }}>R$ {mascaraValor((this.props.location.state.pedido.valor_pedido).toFixed(2))}</Typography>
          </div>

          <div style={{ width: 1, height: 50 }} />
        </div>
      </div>
    )
  }

  Loading() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', }}>
          <div style={{
            paddingLeft: 18, marginTop: 10, display: 'flex',
            backgroundColor: '#fff', flex: 1, alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{ marginTop: 130, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <CircularProgress style={{ color: '#fd5e37', }} />
              </div>
              <Typography variant="subtitle1" style={{
                fontFamily: 'Montserrat',
                marginTop: 15,
                fontSize: 14, width: '100%', textAlign: 'center', width: '100%'
              }}>Carregando ...</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  form() {
    return (
      <div style={{ width: '100%', flex: 1 }}>
        <div className='scow' style={{ overflowY: 'scroll', width: '100%', flex: 1, backgroundColor: 'white' }}>
          <div>
            <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 13, }}>Itens: </Typography>
          </div>
          <div style={{ marginTop: 10 }}>
            {this.state.itens.map((i, key) => (
              <div key={key} style={{ width: '100%', paddingTop: 10, paddingLeft: 10, paddingRight: 5, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', borderRadius: 3, display: 'flex' }}>
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                  <div style={{ width: 40 }}>
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 11, }}>{i.quantidade} x </Typography>
                  </div>
                  <div style={{ width: "90%" }}>
                    <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 11, }}>{i.nome} </Typography>
                  </div>
                </div>
                <div style={{ width: "90%" }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 11, }}>{i.adicional}</Typography>
                </div>
                <div style={{ width: "100%" }}>
                  <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 11, }}>R$ {mascaraValor((i.valor).toFixed(2))}</Typography>
                </div>
              </div>
            ))}
          </div>
          {this.resulm()}
        </div>

      </div>
    )
  }

  noConection() {
    return (
      <div style={{ width: '96%' }}>
        <div style={{ marginTop: '35%', width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <CloudOffOutlinedIcon style={{ fontSize: 120, color: '#8A8A8A' }} />
        </div>
        <div style={{ display: 'flex', marginTop: 10, width: "100%", alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="subtitle1" style={{ fontSize: 15, fontFamily: 'Montserrat' }}>WHOOPS!!!</Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }}>Ocorreu um erro na cominicação com o servidor!!</Typography>
          <div style={{ marginTop: 10, height: 40, flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              console.log('Apertei')
              this.getServidor();
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#fd5e37', fontWeight: '600', }}>Tente novamente</Typography>
          </div>
        </div>
      </div>
    )
  }

  layout() {
    if (this.state.isLoading) {
      return this.Loading()
    } else {
      if (this.state.isErro) {
        return this.noConection()
      } else {
        return this.form()
      }
    }
  }

  header() {
    return (
      <div style={styleHeader}>
        <div style={{ height: 55, width: 200, display: 'flex', flexDirection: 'row', alignItems: 'center', }}
          onClick={() => {
            this.props.history.goBack();
          }}>
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: 22, width: 25, color: '#000' }} />
          <div>
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 18, width: '100%', color: '#000', marginTop: 4, marginLeft: 10 }} numberOfLines={1}>
              Detalhe do pedido
            </Typography>
          </div>
        </div>
        {/* <TouchableOpacity style={{ height: 55, width: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Icon name="sharealt" type="AntDesign" style={{ fontSize: 18, width: 25, color: '#7DC8C3' }} />
        </TouchableOpacity> */}
      </div>
    );
  }

}

const styleHeader = {
  width: '100%',
  height: 55,
  backgroundColor: '#ffffff',
  paddingRight: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: 0,

}

const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(Actions, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(Pedidos);

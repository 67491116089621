import React from 'react';
import { mascaraValor } from './../app/utilites/utilites'
import Typography from '@mui/material/Typography';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

export default function ListPesquisa(props) {
  return (
    <div style={{
      display: 'flex',
      width: "100%", height: 100, flexDirection: "row",
      backgroundColor: '#fff', marginTop: 10, justifyContent: 'space-between',
      borderRadius: 10, borderWidth: 0.5, borderColor: props.padrao ? '#fd5e37' : '#fff'
    }}
      onClick={props.onPress}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }} opacity={props.isRaio ? 1 : 0.5}>
        <div style={{display: 'flex', width: 65, alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={props.Link}
            style={{
              marginTop: 0,
              width: 40,
              height: 40,
              backgroundColor: '#d8dadc',
              position: 'relative',
              borderRadius: 50
            }} />
        </div>
        <div style={{ paddingTop: 13, paddingLeft: 10, flex: 1 }}>
          <Typography variant="subtitle1" style={{ color: '#fd5e37', fontSize: 14, fontFamily: 'Montserrat', fontWeight: 800 }} numberOfLines={1}>{props.nome}</Typography>
          <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat', fontSize: 10, marginTop: 2, width: '95%' }} numberOfLines={1}>{props.endereco}</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', width: 160, justifyContent: 'space-between' }}>
            <Typography variant="subtitle1"  style={{ fontFamily: 'Montserrat', fontSize: 10, marginTop: 3, marginLeft: -3, }} numberOfLines={1}>
              <TwoWheelerOutlinedIcon style={{ fontSize: 10, marginRight: 5 }} />
              {mascaraValor(props.distancia.toFixed(2))} Km
            </Typography>
            <Typography variant="subtitle1"  style={{ fontFamily: 'Montserrat', fontSize: 10, marginTop: 3, marginLeft: -3, color: props.isAberto === 'Aberto' ? 'green' : 'red' }} numberOfLines={1}>
              <TwoWheelerOutlinedIcon style={{ fontSize: 10, marginRight: 5, color: props.isAberto === 'Aberto' ? 'green' : 'red' }} />
              {props.isAberto}
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ width: 15, alignItems: 'flex-end', backgroundColor: '#fff'}}>
        <div style={{ paddingRight: 10, paddingTop: 10 }}>
          <ChevronRightOutlinedIcon style={{ fontSize: 15, color: '#fd5e37' }} />
        </div>
      </div>
    </div>
  )
}
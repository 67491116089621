const state = {
  carrinho: [],
  empresaCarrinho: '-1',
  tipoPagamento: '',
  pagCarteira: 0,
  valorTroco: ''
};

export const CarrinhoReducer = (stateAtual = state, action) => {
  switch (action.type) {
    case 'UPDATE_CAR':
      var listTemp = stateAtual.carrinho;
      listTemp.push(action.produto);
      return {
        ...stateAtual,
        carrinho: listTemp
      };
    case 'ALTERAR_PAGAMENTO':
      return {
        ...stateAtual,
        tipoPagamento: action.pagamento,
      };
    case 'SET_PAG_ONLINE':
      return {
        ...stateAtual,
        pagCarteira: action.obj,
      };
    case 'SET_VL_TROCO':
      return {
        ...stateAtual,
        valorTroco: action.troco,
      };
    case 'GET_CAR':
      return {
        ...state
      };
    case 'SET_CAR_EMP':
      var listTemp = stateAtual.carrinho;
      listTemp = [];
      return {
        ...stateAtual,
        carrinho: [],
        empresaCarrinho: action.empresa
      };
    case 'LIMPAR_CAR':
      return {
        ...stateAtual,
        carrinho: [],
        empresaCarrinho: '-1',
        tipoPagamento: '',
        valorTroco: ''
      };
    case 'DEL_ITEM_CAR':
      var listTemp = stateAtual.carrinho;
      listTemp.splice(action.x, 1);
      if (listTemp.length === 0) {
        return {
          ...stateAtual,
          tipoPagamento: '',
          valorTroco: '',
          empresaCarrinho: '-1',
          carrinho: [],
        };
      } else {
        return {
          ...stateAtual,
          carrinho: listTemp
        };
      }
    default:
      return stateAtual;
  }
};